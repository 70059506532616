import _ from 'lodash'
import { useMemo, useCallback, useState, useRef } from 'react'
import { useUpdateReferralMutation, useReferralProgramEnableMutation } from '@sevenrooms/core/api'
import type { Referral } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { Prompt, Surface, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button, Form } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, VStack, HStack, notify, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text, StatusLabel } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { referralsMessages } from '../../locales/referralsMessages'
import { ReferralProgramEnableModal } from '../ReferralProgramEnableModal'
import { ReferralProgramSaveModal } from '../ReferralProgramSaveModal'
import { ReferredClientForm, type ReferredClientFormFields } from '../ReferredClientForm'
import { ReferrersForm, type ReferrersFormFields } from '../ReferrersForm'
import { useReferralsForm } from './ReferralsForm.zod'

export interface ReferralsFormProps {
  referral: Referral | undefined
}

export function ReferralsForm({ referral }: ReferralsFormProps) {
  const { formatMessage } = useLocales()
  const { venue, venueKey, venueId } = useVenueContext()
  const [updateReferralMutation] = useUpdateReferralMutation()
  const [updateReferralProgramEnableMutation] = useReferralProgramEnableMutation()
  const nav = useNavigation()
  const referralProgramEnabled = referral ? referral.referralProgramEnabled : false
  const [isDraft, setDraft] = useState(!referralProgramEnabled)
  const submitType = useRef<'save' | 'activate' | 'deactivate'>('save')
  const referralsFormSchema = useReferralsForm({ isDraft })
  const referralCloseHref = nav.closeSurfaceHref(routes.manager2.marketing.referrals.referralProgramEnableModal, {
    params: { venueKey, referralProgramEnabled },
  })
  const referralProgramSaveCloseHref = nav.closeSurfaceHref(routes.manager2.marketing.referrals.referralProgramSaveModal, {
    params: { venueKey },
  })

  const form = useForm(referralsFormSchema, { defaultValues: referral, mode: 'onSubmit' })
  const {
    field,
    formState: { isDirty, errors: _errors, dirtyFields },
    getValues,
  } = form

  const globalPerksOptions = useMemo(
    () => referral?.globalPerks?.map(perk => ({ id: perk.id, label: perk.internalName })) ?? [],
    [referral?.globalPerks]
  )

  const localPerksOptions = useMemo(
    () => referral?.localPerks?.map(perk => ({ id: perk.id, label: perk.internalName })) ?? [],
    [referral?.localPerks]
  )

  const onSave = useCallback(() => {
    const formData = getValues()

    try {
      updateReferralMutation({ referral, formData, venueId })
      notify({
        content: formatMessage(referralsMessages.referralProgramSaveSuccess),
        type: 'success',
      })
    } catch {
      notify({
        content: formatMessage(referralsMessages.fetchError),
        type: 'error',
      })
    }
  }, [getValues, formatMessage, referral, updateReferralMutation, venueId])

  const onReferralProgramUpdate = useCallback(
    async (referralProgramEnabled: boolean) => {
      try {
        await updateReferralProgramEnableMutation({
          referralProgramEnabled,
          venueId: venue.id,
          refresh: !referralProgramEnabled || (!isDirty && referralProgramEnabled),
        })

        nav.closeSurface(referralCloseHref)

        if (referralProgramEnabled && isDirty) {
          onSave()
        }
      } catch {
        notify({
          content: formatMessage(referralsMessages.fetchError),
          type: 'error',
        })
      }
    },
    [nav, venue, updateReferralProgramEnableMutation, referralCloseHref, formatMessage, isDirty, onSave]
  )

  const handleOnInvalid = () => ({})

  const handleOnSubmit = useCallback(() => {
    if (submitType.current === 'activate') {
      nav.push(routes.manager2.marketing.referrals.referralProgramEnableModal, {
        params: { venueKey, referralProgramEnabled: true },
      })
    } else if (submitType.current === 'deactivate') {
      nav.push(routes.manager2.marketing.referrals.referralProgramEnableModal, {
        params: { venueKey, referralProgramEnabled: false },
      })
    } else {
      const autotagTiersGlobalChanges = _.some(dirtyFields.referrerAutotagTiers, tier => _.has(tier, 'globalPerks'))
      const referredClientReservationTagsGlobalChanges = _.some(dirtyFields.referredClientReservationTags, tag => _.has(tag, 'globalPerks'))
      const shouldShowModal = autotagTiersGlobalChanges || referredClientReservationTagsGlobalChanges
      if (shouldShowModal) {
        nav.push(routes.manager2.marketing.referrals.referralProgramSaveModal, {
          params: { venueKey: venueId },
        })
      } else {
        onSave()
      }
    }
  }, [nav, venueId, venueKey, onSave, dirtyFields])

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(referralsMessages.referralProgram)} />
      <Prompt
        when={isDirty}
        message={location => {
          const referralsPathname = nav.href(routes.manager2.marketing.referrals, { params: { venueKey }, queryMode: 'clear' })

          if (location.pathname.startsWith(referralsPathname)) {
            return true
          }

          return formatMessage(referralsMessages.referralsUnsavedDataWarning)
        }}
      />
      <Form {...form} onSubmit={handleOnSubmit} onInvalid={handleOnInvalid}>
        <SettingsPageContent
          headerWidth="calc(100% - 274px)"
          secondHeaderMaxWidth="968px"
          title={
            <>
              {formatMessage(referralsMessages.referralProgram)}&nbsp;&nbsp;
              <Text fontSize="s">
                <StatusLabel variant={referralProgramEnabled ? 'success' : 'inactive'}>
                  {referralProgramEnabled
                    ? formatMessage(commonMessages.active).toUpperCase()
                    : formatMessage(commonMessages.inactive).toUpperCase()}
                </StatusLabel>
              </Text>
            </>
          }
          description={formatMessage(referralsMessages.referralsDescription)}
          actions={
            <HStack spacing="s">
              {referralProgramEnabled && (
                <>
                  <Button
                    data-test="sr-referrals-deactivate-btn"
                    type="submit"
                    variant="secondary-warning"
                    onClick={() => {
                      setDraft(true)
                      submitType.current = 'deactivate'
                    }}
                  >
                    {formatMessage(referralsMessages.referralDeactivateProgram)}
                  </Button>
                  <Button
                    data-test="sr-referrals-save-button"
                    type="submit"
                    variant="primary"
                    disabled={!isDirty}
                    onClick={() => {
                      setDraft(false)
                      submitType.current = 'save'
                    }}
                  >
                    {formatMessage(commonMessages.saveChanges)}
                  </Button>
                </>
              )}
              {!referralProgramEnabled && (
                <>
                  <Button
                    data-test="sr-referrals-save-button"
                    type="submit"
                    variant="secondary"
                    disabled={!isDirty}
                    onClick={() => {
                      setDraft(true)
                      submitType.current = 'save'
                    }}
                  >
                    {formatMessage(commonMessages.saveChanges)}
                  </Button>
                  <Button
                    data-test="sr-referrals-activate-btn"
                    type="submit"
                    variant="primary"
                    onClick={() => {
                      setDraft(false)
                      submitType.current = 'activate'
                    }}
                  >
                    {formatMessage(referralsMessages.referralActivateProgram)}
                  </Button>
                </>
              )}
            </HStack>
          }
        >
          <VStack spacing="lm" pb="lm" pl="lm" pr="lm">
            <BaseSection
              title={formatMessage(referralsMessages.referrers)}
              description={formatMessage(referralsMessages.referrersSectionDescription)}
            >
              <VStack p="lm">
                <ReferrersForm
                  fields={field as unknown as ReferrersFormFields}
                  disabled={false}
                  globalPerks={globalPerksOptions}
                  localPerks={localPerksOptions}
                />
              </VStack>
            </BaseSection>

            <BaseSection
              title={formatMessage(referralsMessages.referredClient)}
              description={formatMessage(referralsMessages.referredClientDescription)}
            >
              <VStack p="lm">
                <ReferredClientForm
                  fields={field as unknown as ReferredClientFormFields}
                  disabled={false}
                  globalPerks={globalPerksOptions}
                  localPerks={localPerksOptions}
                />
              </VStack>
            </BaseSection>
          </VStack>
          <Surface destination={routes.manager2.marketing.referrals.referralProgramEnableModal}>
            <Window>
              <ReferralProgramEnableModal closeHref={referralCloseHref} onUpdate={onReferralProgramUpdate} />
            </Window>
          </Surface>
          <Surface destination={routes.manager2.marketing.referrals.referralProgramSaveModal}>
            <Window>
              <ReferralProgramSaveModal
                closeHref={referralProgramSaveCloseHref}
                onSave={() => {
                  nav.closeSurface(referralProgramSaveCloseHref)
                  onSave()
                }}
              />
            </Window>
          </Surface>
        </SettingsPageContent>
      </Form>
    </>
  )
}
