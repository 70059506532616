import { useMemo } from 'react'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in ORDER-819
import { useGetPromoCodeQuery, useGetResourcesQuery } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, Loader, notify, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { PromoCodeContext } from '../hooks/usePromoCodeContext'
import { PromoCodeResourcesContext } from '../hooks/usePromoCodeResourcesContext'
import { promoCodeMessages } from '../locales'
import { PromoCodeForm } from './PromoCodeForm'
import { PromoCodeFormSaveButton } from './PromoCodeFormSaveButton'

export interface CreateEditPromoCodeProps {}

export function CreateEditPromoCode() {
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()

  const params = nav.matchParams(routes.manager2.marketing.promoCode)

  const venueId = venue?.id || ''
  const promoCodeKey = params?.promoCodeKey || ''
  const isCreate = !promoCodeKey || promoCodeKey === 'new'

  const title = useMemo(
    () => formatMessage(isCreate ? promoCodeMessages.promoCodeCreateTitle : promoCodeMessages.promoCodeEditTitle),
    [formatMessage, isCreate]
  )

  const { data: resourcesResponse, error: resourcesError } = useGetResourcesQuery(
    {
      venueId,
    },
    {
      skip: !venueId,
    }
  )

  const {
    data: promoCode,
    isFetching: isPromoCodeFetching,
    error: promoCodeError,
  } = useGetPromoCodeQuery(
    {
      venueId,
      promoCodeKey,
    },
    {
      skip: isCreate,
    }
  )

  if (!venue) {
    return null
  }

  if (promoCodeError || resourcesError) {
    notify({ content: formatMessage(promoCodeMessages.promoCodeLoadErrorMessage), type: 'error' })
    nav.push(routes.manager2.marketing.promoCodesManagement, {
      params: { venueKey: venue.urlKey },
    })
  }

  const isLoading = !isCreate && isPromoCodeFetching

  return (
    <PromoCodeResourcesContext>
      <PromoCodeContext promoCode={promoCode} events={resourcesResponse?.events}>
        <SettingsPageMeta title={title} />
        <SettingsPageContent
          title={title}
          description={formatMessage(promoCodeMessages.promoCodeDescription)}
          headerWidth="calc(100% - 274px)"
          actions={
            <HStack spacing="s">
              <Button
                variant="secondary"
                onClick={() => {
                  nav.push(routes.manager2.marketing.promoCodesManagement, {
                    params: { venueKey: venue.urlKey },
                  })
                }}
                data-test="cancel-button"
              >
                {formatMessage(commonMessages.cancel)}
              </Button>
              {!isLoading && <PromoCodeFormSaveButton venue={venue} isCreate={isCreate} />}
            </HStack>
          }
        >
          <Content pl="lm" pb="lm">
            <VStack spacing="lm">{isLoading ? <Loader /> : <PromoCodeForm />}</VStack>
          </Content>
        </SettingsPageContent>
      </PromoCodeContext>
    </PromoCodeResourcesContext>
  )
}

const Content = styled(Box)`
  background: ${props => props.theme.colors.secondaryBackground};
`
