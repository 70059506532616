import { useLocales } from '@sevenrooms/core/locales'
import { Label, FormInput } from '@sevenrooms/core/ui-kit/form'
import { Grid } from '@sevenrooms/core/ui-kit/layout'
import { venueProfileMessages } from '../../locales'
import type { SocialMediaFieldKey, SocialMediaFormFields } from './SocialMediaForm.zod'

export interface SocialMediaFormProps {
  fields: SocialMediaFormFields
}

const socialMediaConfig = [
  { fieldKey: 'facebookLink', labelMessage: venueProfileMessages.facebookLabel },
  { fieldKey: 'instagramLink', labelMessage: venueProfileMessages.instagramSocialLabel },
  { fieldKey: 'snapchatLink', labelMessage: venueProfileMessages.snapchatSocialLabel },
  { fieldKey: 'twitterLink', labelMessage: venueProfileMessages.twitterSocialLabel },
  { fieldKey: 'tiktokLink', labelMessage: venueProfileMessages.tiktokSocialLabel },
]

export function SocialMediaForm({ fields }: SocialMediaFormProps) {
  const { formatMessage } = useLocales()
  const placeholder = formatMessage(venueProfileMessages.protocolPlaceholder)

  return (
    <Grid gridTemplateColumns="1fr 1fr" columnGap="lm" rowGap="m">
      {socialMediaConfig.map(({ fieldKey, labelMessage }) => (
        <Label key={fieldKey} primary={formatMessage(labelMessage)}>
          <FormInput field={fields.prop(fieldKey as SocialMediaFieldKey)} placeholder={placeholder} />
        </Label>
      ))}
    </Grid>
  )
}
