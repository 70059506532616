import { defineMessages } from '@sevenrooms/core/locales'

export const marketingHomeMessages = defineMessages({
  marketingHomeTitle: {
    id: 'marketingHome.title',
    defaultMessage: 'Home',
  },
  bookieIntegrationHeader: {
    id: 'marketingHome.bookieIntegrationHeader',
    defaultMessage: 'Connect your Reservation Provider',
  },
  bookieIntegrationBody: {
    id: 'marketingHome.bookieIntegrationBody',
    defaultMessage:
      'Before you can start sending automated emails, please connect your reservation provider using our third-party integration.',
  },
  bookieConnectButton: {
    id: 'marketingHome.bookieConnectButton',
    defaultMessage: 'Connect Now',
  },
  bookieConnectDisclaimer: {
    id: 'marketingHome.bookieConnectDisclaimer',
    defaultMessage:
      "By clicking on this link, you acknowledge that you are leaving our website and that SevenRooms are not responsible for the content, accuracy, availability, or any other aspects of the third-party website. Any transactions, interactions, or disputes that arise from your use of the third-party site are solely between you and the third party. Please review the third party's applicable policies and terms.",
  },
  bookieCommunicatingStateStart: {
    id: 'marketingHome.bookieCommunicatingStateStart',
    defaultMessage: 'Communicating with Bookie to Authenticate',
  },
  bookieCommunicatingWaitingForMapping: {
    id: 'marketingHome.bookieCommunicatingWaitingForMapping',
    defaultMessage: 'Credentials received. Waiting for mapping',
  },
  bookieCommunicatingKeepWindowOpen: {
    id: 'marketingHome.bookieCommunicatingKeepWindowOpen',
    defaultMessage: 'Please keep this window open while you authenticate with bookie',
  },
  bookieCommunicatingProcessTime: {
    id: 'marketingHome.bookieCommunicatingProcessTime',
    defaultMessage: 'This process can take a few minutes.',
  },
  bookieCommunicatingHelp: {
    id: 'marketingHome.bookieCommunicatingHelp',
    defaultMessage: 'Need help with authentication?',
  },
  heroVenueName: {
    id: 'marketingHome.heroVenueName',
    defaultMessage: '{venueName},',
  },
  heroWelcome: {
    id: 'marketingHome.heroWelcome',
    defaultMessage: 'Welcome to SevenRooms',
  },
  heroIntro: {
    id: 'marketingHome.heroIntro',
    defaultMessage: "Let's toast to your incredible highlights since the launch of your CRM & Marketing Suite.",
  },
  importBannerTitle: {
    id: 'marketingHome.importBannerTitle',
    defaultMessage: 'Data Import in Progress',
  },
  importBannerDescription: {
    id: 'marketingHome.importBannerDescription',
    defaultMessage: 'Any eligible Automated Emails will send once the import is complete. Imports can take up to 24 hours.',
  },
  onboardVenuesTitle: {
    id: 'marketingHome.onboardVenuesTitle',
    defaultMessage: 'Set Up {count} {count, plural, one {venue} other {venues}}',
  },
  onboardVenuesDescription: {
    id: 'marketingHome.onboardVenuesDescription',
    defaultMessage: 'Set up your other {count, plural, one {venue} other {venues}}',
  },
  enableEmailsTitle: {
    id: 'marketingHome.enableEmailsTitle',
    defaultMessage: 'Enable Automated Emails',
  },
  enableEmailsDescription: {
    id: 'marketingHome.enableEmailsDescription',
    defaultMessage: 'Visit the Automated Emails tab to get started',
  },
  setSpendPerCoverTitle: {
    id: 'marketingHome.setSpendPerCoverTitle',
    defaultMessage: 'Add Your Average Cover Spend',
  },
  setSpendPerCoverDescription: {
    id: 'marketingHome.setSpendPerCoverDescription',
    defaultMessage: 'Used to calculate the return on investment you will see',
  },
  noReviews: {
    id: 'marketingHome.noReviews',
    defaultMessage: 'No Reviews',
  },
  addReviewSites: {
    id: 'marketingHome.addReviewSites',
    defaultMessage: 'Add Review Sites',
  },
  guestSatisfactionTitle: {
    id: 'marketingHome.guestSatisfactionTitle',
    defaultMessage: 'Guest Satisfaction',
  },
  guestSatisfactionAction: {
    id: 'marketingHome.guestSatisfactionAction',
    defaultMessage: 'View',
  },
  overallAverage: {
    id: 'marketingHome.overallAverage',
    defaultMessage: 'Overall Average',
  },
  guestSatisfactionPositive: {
    id: 'marketingHome.guestSatisfactionPositive',
    defaultMessage: 'Positive',
  },
  guestSatisfactionNegative: {
    id: 'marketingHome.guestSatisfactionNegative',
    defaultMessage: 'Negative',
  },
  guestSatisfactionRecentReview: {
    id: 'marketingHome.guestSatisfactionRecentReview',
    defaultMessage: 'Recent Review',
  },
  guestSatisfactionDescription: {
    id: 'marketingHome.guestSatisfactionDescription',
    defaultMessage: 'Understand guest sentiment from review sites across the internet.',
  },
  automatedEmailsTitle: {
    id: 'marketingHome.automatedEmailsTitle',
    defaultMessage: 'Automated Emails',
  },
  automatedEmailsAction: {
    id: 'marketingHome.automatedEmailsAction',
    defaultMessage: 'View',
  },
  automatedEmailsNoCampaigns: {
    id: 'marketingHome.automatedEmailsNoCampaigns',
    defaultMessage: 'No Campaigns',
  },
  automatedEmailsSetup: {
    id: 'marketingHome.automatedEmailsSetup',
    defaultMessage: 'Set Up Automated Emails',
  },
  automatedEmailsStatsDescription: {
    id: 'marketingHome.automatedEmailsStatsDescription',
    defaultMessage: 'Get insight into how Automated Emails are bringing guests back to your venue and generating revenue on your behalf.',
  },
  automatedEmailStatsRevenue: {
    id: 'marketingHome.automatedEmailStatsRevenue',
    defaultMessage: 'Incremental Revenue Generated',
  },
  automatedEmailStatsEmailsToday: {
    id: 'marketingHome.automatedEmailStatsEmailsToday',
    defaultMessage: 'Emails Sent Today',
  },
  automatedEmailStatsActiveCampaigns: {
    id: 'marketingHome.automatedEmailStatsActiveCampaigns',
    defaultMessage: 'Active Campaigns',
  },
  automatedEmailStatsRecipients: {
    id: 'marketingHome.automatedEmailStatsRecipients',
    defaultMessage: 'Total Recipients',
  },
  automatedEmailStatsOpenRate: {
    id: 'marketingHome.automatedEmailStatsOpenRate',
    defaultMessage: 'Open Rate',
  },
  automatedEmailStatsHighestPerformingCampaign: {
    id: 'marketingHome.automatedEmailStatsHighestPerformingCampaign',
    defaultMessage: 'Highest Performing Campaign',
  },
  addReviewSiteLinksTitle: { id: 'marketingHome.addReviewSiteLinksTitle', defaultMessage: 'Add Review Site Links' },
  addReviewSiteLinksDescription: {
    id: 'marketingHome.addReviewSiteLinksDescription',
    defaultMessage: 'Get a 360° view of how guests speak about you online',
  },
  faqTitle: {
    id: 'marketingHome.faqTitle',
    defaultMessage: 'Frequently asked questions',
  },
  faqActionTitle: {
    id: 'marketingHome.faqActionTitle',
    defaultMessage: 'We’re ready to help you get set up',
  },
  faqActionDescription: {
    id: 'marketingHome.faqActionDescription',
    defaultMessage: 'Contact your Customer Success Manager',
  },
  faq1question: {
    id: 'marketingHome.faq1question',
    defaultMessage: 'How can I maximize my return on investment with the CRM & Marketing Suite?',
  },
  faq1answerPart1: {
    id: 'marketingHome.faq1answerPart1',
    defaultMessage:
      'Our 11 Automated Emails are proven to bring guests back to your restaurant to dine and spend money. To maximize your investment, we recommend having all 11 emails activated with minimal changes to the audience to take advantage of the proven strategy.',
  },
  faq1answerPart2: {
    id: 'marketingHome.faq1answerPart2',
    defaultMessage: 'Already have all 11 Automated Emails turned on, and ready to level up?',
  },
  faq1answerPart3: {
    id: 'marketingHome.faq1answerPart2',
    defaultMessage: 'with one of our Marketing Experts here.',
  },
  faq1answerLinkText: {
    id: 'marketingHome.faq1answerLinkText',
    defaultMessage: 'Schedule a session',
  },
  faq2question: {
    id: 'marketingHome.faq2question',
    defaultMessage: 'Can I customize the Automated Emails?',
  },
  faq2answer: {
    id: 'marketingHome.faq2answer',
    defaultMessage:
      'You can customize the Automated Emails to reflect your venue’s brand, but keep in mind the default text was written by marketing experts for optimal performance. We recommend activating the automated campaigns as-is before customizing them, so you can see what is resonating with your guests and make data-driven decisions.',
  },
  faq3question: {
    id: 'marketingHome.faq3question',
    defaultMessage: 'Can I design or add images to my Automated Emails?',
  },
  faq3answer: {
    id: 'marketingHome.faq3answer',
    defaultMessage:
      'We’ll let you in on a secret: Automated Emails are your magic trick! Guests will believe that your GM, Chef, or Owner took the time to personally write them an email. In order to feel one-to-one, emails should be plain-text and not include any images or design.',
  },
  faq4question: {
    id: 'marketingHome.faq4question',
    defaultMessage: 'Can I use Automated Emails to send a newsletter?',
  },
  faq4answer: {
    id: 'marketingHome.faq4answer',
    defaultMessage:
      'Automated Emails are trigger-based and are sent to guests after they take a specific action. While it is not possible to use Automated Emails to send one-time sends, like newsletters, SevenRooms offers an easy-to-use Email Marketing tool that can be added to your CRM & Marketing package.',
  },
  faq5question: {
    id: 'marketingHome.faq5question',
    defaultMessage: 'How is the revenue generated by Automated Emails calculated?',
  },
  faq5answer: {
    id: 'marketingHome.faq5answer',
    defaultMessage:
      'Your estimated revenue is based on your average cover spend that was provided during the onboarding process multiplied by the number of covers generated by each email. Make changes by navigating to Marketing > Profile Information.',
  },
} as const)
