import { useCallback, useMemo, useState } from 'react'
import { useCreatePerkMutation, useUpdatePerkMutation } from '@sevenrooms/core/api'
import type { Concierge, Perk, GenericTagGroup } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Prompt, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { getTimeStringFromNumber } from '@sevenrooms/core/timepiece'
import { Form, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, notify, VStack, DividerLine, Breadcrumbs, Box } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { PerkActionButtons } from '../../components/PerkActionButtons'
import { PerkAssociatedTagsForm } from '../../components/PerkAssociatedTagsForm'
import { PerkAvailabilityRestrictionsForm } from '../../components/PerkAvailabilityRestrictionsForm'
import { PerkConciergeSettingsForm } from '../../components/PerkConciergeSettingsForm'
import { getConciergesSelectedFormState } from '../../components/PerkConciergeSettingsForm/PerkConciergeSettingsForm.zod'
import { PerkNameForm } from '../../components/PerkNameForm'
import { PerkTypeForm } from '../../components/PerkTypeForm'
import { PerkVisibilityForm } from '../../components/PerkVisibilityForm'
import { perksMessages } from '../../locales'
import {
  type CreateEditPerkFormData,
  useCreateEditPerkForm,
  formToPerk,
  getCreateEditPerkDefaultValues,
  getTagsGroupsToRemovePerkFrom,
} from './CreateEditPerkForm.zod'

export interface CreateEditPerkFormProps {
  perk?: Perk
  venueConcierges?: Concierge[]
  reservationTagGroups: GenericTagGroup[]
  clientTagGroups: GenericTagGroup[]
}

export function CreateEditPerkForm({ perk, venueConcierges, reservationTagGroups, clientTagGroups }: CreateEditPerkFormProps) {
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const createEditFormSchema = useCreateEditPerkForm()

  const { formatMessage } = useLocales()
  const perkId = perk?.id
  const { venue, venueId } = useVenueContext()
  const nav = useNavigation()
  const [updatePerk] = useUpdatePerkMutation()
  const [createPerk] = useCreatePerkMutation()

  function getPageTitle() {
    return perkId
      ? formatMessage(perksMessages.editPerk, { perkName: perk?.internalName ?? 'Perk' })
      : formatMessage(perksMessages.createNewPerk)
  }

  const onCancelButtonClick = useCallback(() => {
    nav.push(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })
  }, [nav, venue])

  const form = useForm(createEditFormSchema, {
    defaultValues: useMemo(
      () =>
        getCreateEditPerkDefaultValues({
          perk,
          venueConcierges,
          conciergeAccess: !venue.isNoConciergeAccess,
          startOfDayTime: getTimeStringFromNumber(venue.startOfDayHour),
        }),
      [perk, venue.isNoConciergeAccess, venueConcierges, venue.startOfDayHour]
    ),
  })

  const conciergeTreeData: TreeNode<string>[] = useMemo(
    () =>
      getConciergesSelectedFormState({
        conciergeAccessKeymap: perk?.conciergeAccessKeymap,
        perkId,
        venueConcierges,
      }),
    [perk?.conciergeAccessKeymap, perkId, venueConcierges]
  )

  const { field, reset, watch, formState, getValues } = form
  const { isSubmitting, isDirty } = formState

  const handleOnSubmit = useCallback(
    async (createEditPerkFormData: CreateEditPerkFormData) => {
      reset(getValues())

      if (perkId) {
        try {
          await updatePerk({
            perkId,
            perk: formToPerk({ createEditPerkFormData, venueConcierges }),
            venueId,
            tagGroupsToRemovePerkFrom: perk ? getTagsGroupsToRemovePerkFrom(perk, createEditPerkFormData) : [],
          }).unwrap()
          notify({
            content: formatMessage(perksMessages.perkUpdateSuccess, { perkInternalName: watch('internalName') || 'Perk' }),
            type: 'success',
          })
          nav.push(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })
        } catch (error) {
          notify({
            content: formatMessage(perksMessages.perkCreateUpdateError),
            type: 'error',
          })
        }
      } else {
        try {
          await createPerk({ perk: formToPerk({ createEditPerkFormData, venueConcierges }), venueId }).unwrap()
          notify({
            content: formatMessage(perksMessages.perkCreateSuccess, { perkInternalName: watch('internalName') || 'Perk' }),
            type: 'success',
          })
          nav.push(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })
        } catch (error) {
          notify({
            content: formatMessage(perksMessages.perkCreateUpdateError),
            type: 'error',
          })
        }
      }
    },
    [createPerk, formatMessage, getValues, nav, perk, perkId, reset, updatePerk, venue.urlKey, venueConcierges, venueId, watch]
  )

  const handleOnInvalid = useCallback(() => {
    // Open additional settings area to display validation errors
    setCollapsed(false)
  }, [])

  return (
    <>
      <Prompt when={isDirty} message={formatMessage(perksMessages.perkAreYouSure)} />
      <SettingsPageMeta venue={venue?.name} title={getPageTitle()} />
      <Form {...form} onSubmit={handleOnSubmit} onInvalid={handleOnInvalid}>
        <SettingsPageContent
          headerWidth="calc(100% - 274px)"
          title={getPageTitle()}
          description={formatMessage(perksMessages.perkDescription)}
          actions={<PerkActionButtons disabled={isSubmitting} onCancel={onCancelButtonClick} perkId={perkId} />}
          breadcrumbs={
            <Breadcrumbs>
              <Link to={nav.href(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })}>
                {formatMessage(perksMessages.perksManagement)}
              </Link>
              <Text>{formatMessage(perkId ? perksMessages.editPerkBreadcrumb : perksMessages.createNewPerk)}</Text>
            </Breadcrumbs>
          }
        >
          <Box pt="lm" pl="lm" pr="lm" width="100%">
            <BaseSection title={formatMessage(perksMessages.perkGeneral)}>
              <Box p="lm" width="100%">
                <VStack spacing="m">
                  <PerkNameForm disabled={isSubmitting} internalNameField={field.prop('internalName')} />
                </VStack>
              </Box>
              <DividerLine ml="m" mr="m" />
              <Box p="lm" width="100%">
                <PerkVisibilityForm field={field.prop('visibility')} />
              </Box>
              <DividerLine ml="m" mr="m" />
              <Box p="lm" width="100%">
                <PerkTypeForm disabled={isSubmitting || Boolean(perkId)} perkTypeField={field.prop('perkType')} />
              </Box>
              <DividerLine ml="m" mr="m" />
              <Box p="lm" width="100%">
                <PerkAssociatedTagsForm
                  field={field.prop('associatedTags')}
                  clientTagGroups={clientTagGroups}
                  reservationTagGroups={reservationTagGroups}
                  disabled={isSubmitting}
                />
              </Box>
            </BaseSection>
          </Box>
          <Box p="lm" width="100%">
            <PerkAvailabilityRestrictionsForm field={field.prop('availabilityRestrictions')} disabled={isSubmitting} />
          </Box>
          {!venue.isNoConciergeAccess && (
            <Box pl="lm" pr="lm" width="100%">
              <PerkConciergeSettingsForm
                disabled={isSubmitting}
                field={field.prop('conciergeSettings')}
                onToggleCollapse={() => setCollapsed(collapsed => !collapsed)}
                collapsed={collapsed}
                conciergeTreeData={conciergeTreeData}
              />
            </Box>
          )}
        </SettingsPageContent>
      </Form>
      {/* Adding space to the bottom of this page so that pendo feedback does not overlap the expand section */}
      <Box minHeight="500px" />
    </>
  )
}
