// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack, Image, PaddedBox, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, List, ListItem, Text } from '@sevenrooms/core/ui-kit/typography'
import { mgrAcctUpgradesMessages } from '../locales'
import { NumericalStat, type NumericalStatData } from './NumericalStat'

const CONTENT_BACKGROUND_MARGIN_X = 'xl'
const CONTENT_BACKGROUND_MARGIN_Y = 'l'
const PAGE_CONTENT_PADDING_X = '4xl'
const PAGE_CONTENT_PADDING_Y = 'xl'
const NEW_BANNER_PADDING_X = 's'
const NEW_BANNER_PADDING_Y = 'xs'
const SECTION_SPACING = 'l'
const CONTENT_BORDER_RADIUS = 'm'
const PAGE_CONTENT_RADIUS = 'm'

const ContentBackground = styled(HStack)`
  background: linear-gradient(188deg, #cfe4f5 6.26%, #f4e9f9 95.58%);
`

function GetDemoButton({ ctaDestination, analyticsKeySuffix }: GetDemoButtonProps) {
  const { formatMessage } = useLocales()
  return (
    <Button variant="primary" href={ctaDestination} isExternal data-test={`demo-btn-${analyticsKeySuffix}`}>
      {formatMessage(mgrAcctUpgradesMessages.getDemo)}
    </Button>
  )
}

export function AvailableUpgradePromoPage({
  title,
  leadDescription,
  numericalStats,
  bullets,
  imageHref,
  imageAlt,
  ctaDestination,
  useNewFeatureFlag,
  analyticsKeySuffix,
}: AvailableUpgradePromoPageProps) {
  const { formatMessage } = useLocales()
  const statsWithFootnotes = numericalStats?.filter(stat => !!stat.footnotes?.length) || []
  let footnoteIdx = 0

  return (
    <PaddedBox
      backgroundColor="primaryBackground"
      pl={PAGE_CONTENT_PADDING_X}
      pr={PAGE_CONTENT_PADDING_X}
      pt={PAGE_CONTENT_PADDING_Y}
      pb={PAGE_CONTENT_PADDING_Y}
      mt="xxl"
      ml="3xl"
      width="800px"
      minHeight="calc(100vh - 58px - 48px)" /* Warning: implicit dependencies on header height and top margin */
      borderTopRightRadius={PAGE_CONTENT_RADIUS}
      borderTopLeftRadius={PAGE_CONTENT_RADIUS}
    >
      <ContentBackground
        alignItems="center"
        pl={CONTENT_BACKGROUND_MARGIN_X}
        pr={CONTENT_BACKGROUND_MARGIN_X}
        borderRadius={CONTENT_BORDER_RADIUS}
        pt={CONTENT_BACKGROUND_MARGIN_Y}
      >
        <HStack justifyContent="center" alignItems="flex-end" width="250px" minWidth="250px" mr="xl">
          <Image src={imageHref} alt={imageAlt} maxWidth="100%" />
        </HStack>
        <VStack alignItems="flex-start" justifyContent="initial">
          {useNewFeatureFlag && (
            <Box
              mb="m"
              borderRadius="s"
              backgroundColor="primaryBackground"
              pt={NEW_BANNER_PADDING_Y}
              pb={NEW_BANNER_PADDING_Y}
              pl={NEW_BANNER_PADDING_X}
              pr={NEW_BANNER_PADDING_X}
              width="auto"
              display="inline-block"
            >
              <Text color="linkColor" type="span" textStyle="body1">
                {formatMessage(mgrAcctUpgradesMessages.newFeatureFlag)}
              </Text>
            </Box>
          )}
          <Header type="h1" mb="xs">
            {title}
          </Header>
          <Text textStyle="h3" color="secondaryFont">
            {leadDescription}
          </Text>
          <Box mt="l" mb={CONTENT_BACKGROUND_MARGIN_Y}>
            <GetDemoButton ctaDestination={ctaDestination} analyticsKeySuffix={`head-${analyticsKeySuffix}`} />
          </Box>
        </VStack>
      </ContentBackground>
      {numericalStats && (
        <HStack spacing="m" alignItems="center" mt={SECTION_SPACING}>
          {numericalStats.map(stat => {
            const outElement = <NumericalStat stat={stat} borderRadius={PAGE_CONTENT_RADIUS} startIdx={footnoteIdx} />
            footnoteIdx += stat.footnotes?.length || 0

            return outElement
          })}
        </HStack>
      )}
      {bullets && (
        <VStack spacing="l" mt={SECTION_SPACING}>
          {bullets.map(bulletText => (
            <HStack key={bulletText} spacing="m" alignItems="center">
              <Icon name="VMSWeb-check-badge" color="primaryButton" size="lg" />
              <Text textStyle="body1" color="secondaryFont">
                {bulletText}
              </Text>
            </HStack>
          ))}
        </VStack>
      )}
      <VStack alignItems="center" mt="xl">
        <GetDemoButton ctaDestination={ctaDestination} analyticsKeySuffix={`body-${analyticsKeySuffix}`} />
      </VStack>
      {!!statsWithFootnotes.length && (
        <Box mt="4xl">
          <List variant="ordered" type="1">
            {statsWithFootnotes.map(stat =>
              stat.footnotes?.map(footnote => (
                <Text color="secondaryFont" fontSize="xs" lineHeight="xs" fontStyle="italic" key={footnote}>
                  <ListItem>{footnote}</ListItem>
                </Text>
              ))
            )}
          </List>
        </Box>
      )}
    </PaddedBox>
  )
}

interface GetDemoButtonProps {
  ctaDestination: string
  analyticsKeySuffix: string
}
interface AvailableUpgradePromoPageProps extends GetDemoButtonProps {
  title: string
  leadDescription: string
  imageHref: string
  imageAlt: string
  bullets?: string[]
  numericalStats?: NumericalStatData[]
  useNewFeatureFlag?: boolean
}
