import { useState } from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useDestination, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { oneTimeEmailCenterMessages } from '../../locales'

export interface ConfirmEditOneTimeEmailModalProps {
  onConfirm: (campaignId: string) => void
}

export function ConfirmEditOneTimeEmailModal({ onConfirm }: ConfirmEditOneTimeEmailModalProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const [disableButton, setDisableButton] = useState(false)
  const { params } = useDestination(routes.manager2.marketing.oneTimeEmailCenter.confirmEditModal)
  const campaignId = params?.campaignId
  const closeHref =
    venue && params
      ? nav.closeSurfaceHref(routes.manager2.marketing.oneTimeEmailCenter.confirmEditModal, {
          params: { venueKey: venue?.urlKey, campaignId },
        })
      : undefined

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref ?? ''}>
        <VStack spacing="s">
          <Status kind="warning">Warning</Status>
          <ModalTitle title={formatMessage(oneTimeEmailCenterMessages.confirmModalTitle)} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text>{formatMessage(oneTimeEmailCenterMessages.confirmModalInformation)}</Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button href={closeHref} variant="secondary" data-test="cancel-button">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onConfirm(campaignId)
              setDisableButton(true)
            }}
            disabled={disableButton}
            data-test="confirm-edit-button"
          >
            {formatMessage(oneTimeEmailCenterMessages.confirmModalDelete)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
