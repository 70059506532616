import { useMemo, useCallback } from 'react'
import type { AccessRuleToShow } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { MultiSelect } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AccessRuleSelectorLocales } from './AccessRuleSelector.locales'

interface AccessRuleSelectorProps {
  accessRules: AccessRuleToShow[]
  selectedAccessRuleIds: string[]
  setSelectedAccessRuleIds: (selectedIds: string[]) => void
}
export function AccessRuleSelector({ accessRules, setSelectedAccessRuleIds, selectedAccessRuleIds }: AccessRuleSelectorProps) {
  const { formatMessage } = useLocales()
  const accessRuleOptions = useMemo(() => {
    if (accessRules) {
      return accessRules.map(rule => ({
        id: rule.id,
        label: rule.name,
        description: (
          <Box pl="l" width="100%">
            <HStack>
              <VStack flex="1">
                <Text fontSize="m" color="secondaryFont">
                  {formatMessage(AccessRuleSelectorLocales.accessRuleSelectDays)}
                </Text>
              </VStack>
              <VStack flex="3">
                <Text fontSize="m" color="secondaryFont">
                  {rule.days}
                </Text>
              </VStack>
            </HStack>
            <HStack width="100%">
              <VStack flex="1" mr="s">
                <Text fontSize="m" color="secondaryFont">
                  {formatMessage(AccessRuleSelectorLocales.accessRuleSelectDateRange)}
                </Text>
              </VStack>
              <VStack flex="3" mr="s">
                <Text fontSize="m" color="secondaryFont">
                  {rule.dateRange}
                </Text>
              </VStack>
            </HStack>
            <HStack width="100%">
              <VStack flex="1" mr="s">
                <Text fontSize="m" color="secondaryFont">
                  {formatMessage(AccessRuleSelectorLocales.accessRuleSelectTime)}
                </Text>
              </VStack>
              <VStack flex="3" mr="s">
                <Text fontSize="m" color="secondaryFont">
                  {rule.time}
                </Text>
              </VStack>
            </HStack>
            <HStack width="100%">
              <VStack flex="1" mr="s">
                <Text fontSize="m" color="secondaryFont">
                  {formatMessage(AccessRuleSelectorLocales.accessRuleSelectPartySize)}
                </Text>
              </VStack>
              <VStack flex="3" mr="s">
                <Text fontSize="m" color="secondaryFont">
                  {rule.partySize}
                </Text>
              </VStack>
            </HStack>
          </Box>
        ),
      }))
    }
    return []
  }, [accessRules, formatMessage])

  const onAccessRuleIdsChange = useCallback(
    (selectedIds: string[]) => {
      setSelectedAccessRuleIds(selectedIds)
    },
    [setSelectedAccessRuleIds]
  )

  return (
    <MultiSelect
      options={accessRuleOptions}
      onChange={onAccessRuleIdsChange}
      initialSelectedIds={selectedAccessRuleIds}
      searchable
      useCheckboxItems
      wrapText
    />
  )
}
