import { useCallback, useMemo, useState, useRef } from 'react'
import {
  type OneTimeEmailCampaignConfig,
  type OneTimeEmailCampaignType,
  useCreateEmailCampaignMutation,
  useUpdateEmailCampaignsMutation,
  type OneTimeEmailCampaignTemplate,
  type EmailCampaignResult,
} from '@sevenrooms/core/api'
import {
  type GenericTagGroup,
  MarketingCampaignOneTimeSendTypeEnum,
  OneTimeEmailCampaignStatusEnum,
  MarketingCampaignTypeEnum,
  type ExperiencesData,
  type VenueProfile,
  type GroupVenue,
} from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { ActionsButton, Button, Form, useTopolContext, type TagOption } from '@sevenrooms/core/ui-kit/form'
import { useBeforeUnload } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack, notify, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import {
  Audience,
  emailBuilderMessages,
  generateEmailCampaignPreviewTemplate,
  SendTestEmailModal,
  TIMES_CHOICES,
  extractAutoTagIds,
  getPlainTextFromHtml,
  campaignBuilderMessages,
  packOneTimeSendConfig,
  EmailContentFromScratch,
  EmailDetails,
  CancelEmailEditModal,
  type EmailDetailsFormField,
  type AudienceFormField,
} from '@sevenrooms/marketing'
import { SettingsPageContent, SettingsPageMeta, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import type { EmailContentFormField } from '@sevenrooms/mgr-marketing-ongoing-email-center/components'
import { ConfirmSendEmailModal } from '../ConfirmSendEmailModal'
import { OneTimeSendingSchedule, type OneTimeSendingScheduleFormField } from '../OneTimeSendingSchedule'
import {
  getOneTimeEmailBuilderFormDefaultValues,
  useOneTimeEmailCampaignForm,
  type OneTimeEmailCampaignFormData,
} from './OneTimeEmailCampaignForm.zod'

export interface OneTimeEmailCampaignFormProps {
  emailCampaign?: OneTimeEmailCampaignType
  experiencesData: ExperiencesData
  venueProfile: VenueProfile
  autoTags: GenericTagGroup[]
  isDuplicate: Boolean
  isEdit: Boolean
  parentTemplate?: OneTimeEmailCampaignTemplate
  groupVenues: GroupVenue[]
}

export function OneTimeEmailCampaignForm({
  emailCampaign,
  experiencesData,
  venueProfile,
  autoTags,
  isDuplicate,
  isEdit,
  parentTemplate,
  groupVenues,
}: OneTimeEmailCampaignFormProps) {
  const [isCancelled, setIsCancelled] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const { venueSettings } = useVenueSettingsContext()
  const { isLoading: isTopolLoading, save: saveTopol } = useTopolContext()
  const isTopolEnabled = !!venueSettings?.topol_editor_enabled
  const useTopolOnEdit = isEdit || isDuplicate ? !!emailCampaign?.usingTopolEditor : true
  const useTopolParentTemplate = parentTemplate ? !!parentTemplate?.usingTopolEditor : useTopolOnEdit
  const shouldUseTopol = isTopolEnabled && useTopolParentTemplate
  const { formatMessage, formatList } = useLocales()
  const nav = useNavigation()

  const { venue, venueId } = useVenueContext()
  const venueKey = venue?.urlKey || ''
  let cancelModalCloseHref = null
  if (isDuplicate && emailCampaign) {
    cancelModalCloseHref =
      nav
        .href(routes.manager2.marketing.oneTimeEmailCenter.createOneTimeEmail, {
          params: { venueKey: venue.urlKey },
          query: { campaignToDuplicateId: emailCampaign.id, parentTemplateId: '' },
        })
        .split('&')[0] || ''
  } else {
    cancelModalCloseHref = emailCampaign?.id
      ? nav.href(routes.manager2.marketing.oneTimeEmailCenter.editOneTimeEmail, {
          params: { venueKey: venue.urlKey, campaignId: emailCampaign.id },
          queryMode: 'clear',
        })
      : nav.href(routes.manager2.marketing.oneTimeEmailCenter.createOneTimeEmail, {
          params: { venueKey: venue.urlKey },
          queryMode: 'clear',
        })
  }
  const goToOffers = useRef<boolean>(false)
  const setGoToOffers = () => {
    goToOffers.current = true
  }

  const getDuplicateCampaignName = useCallback(
    () => `${formatMessage(campaignBuilderMessages.copyOf)} ${emailCampaign?.campaignName}`,
    [emailCampaign?.campaignName, formatMessage]
  )

  const campaignName = useMemo(
    () => (isDuplicate ? getDuplicateCampaignName() : emailCampaign?.campaignName ?? ''),
    [isDuplicate, getDuplicateCampaignName, emailCampaign?.campaignName]
  )

  const defaultValues = useMemo(
    () =>
      getOneTimeEmailBuilderFormDefaultValues(emailCampaign ? { ...emailCampaign, campaignName } : parentTemplate, autoTags, venueProfile),
    [emailCampaign, campaignName, autoTags, parentTemplate, venueProfile]
  )

  const form = useForm(useOneTimeEmailCampaignForm(), {
    defaultValues,
  })

  const {
    field,
    formState: { isDirty },
    setValue,
    getValues,
    trigger,
    reset,
  } = form

  const formRef = useRef<HTMLFormElement>(null)
  const shouldExit = useRef<boolean>(false)
  const newCampaignId = useRef<string>('')

  useBeforeUnload(isDirty && !isCancelled && !isSaving)

  const setCampaignStateActive = () => {
    setValue('campaignState', OneTimeEmailCampaignStatusEnum.ACTIVE)
  }
  const [createEmailCampaign, { isLoading: isCreating }] = useCreateEmailCampaignMutation()
  const [updateEmailCampaignsQuery, { isLoading: isUpdating }] = useUpdateEmailCampaignsMutation()

  const isActionDisabled = useMemo(
    () => isCreating || isUpdating || (isEdit && emailCampaign?.status !== OneTimeEmailCampaignStatusEnum.DRAFT) || isTopolLoading,
    [isCreating, isUpdating, isEdit, emailCampaign?.status, isTopolLoading]
  )

  const packOneTimeEmailBuilderFormData = useCallback(
    (formData: OneTimeEmailCampaignFormData): OneTimeEmailCampaignConfig => ({
      oneTimeSendTimeConfig: packOneTimeSendConfig(formData),
      campaignType: MarketingCampaignTypeEnum.ONE_TIME,
      isAnyClientTags: true,
      status: formData.campaignState,
      campaignName: formData.campaignName,
      emailReplyTo: formData.replyToAddress,
      emailSender: getPlainTextFromHtml(formData.emailSender.value ?? ''),
      emailSubject: getPlainTextFromHtml(formData.subjectLine.value ?? ''),
      emailBody: formData.emailBodySections[0]?.value,
      previewText: formData.emailPreview.value,
      recipientClientTags: extractAutoTagIds(formData.recipientAutotags),
      recipientClientTagsExclude: extractAutoTagIds(formData.excludedAutotags),
      parentTemplateId: parentTemplate?.id ?? emailCampaign?.parentTemplateId,
      expressConsentOverride: formData.expressConsentOverride,
      usingTopolEditor: shouldUseTopol,
      topolTemplate: shouldUseTopol ? formData.topolTemplate : undefined,
    }),
    [emailCampaign?.parentTemplateId, parentTemplate?.id, shouldUseTopol]
  )

  async function trySaveEmailCampaign(formData: OneTimeEmailCampaignFormData) {
    const campaignData = packOneTimeEmailBuilderFormData(formData)
    if (formData.campaignState === OneTimeEmailCampaignStatusEnum.ACTIVE) {
      setIsSaving(true)
      nav.push(routes.manager2.marketing.oneTimeEmailCenter.confirmSendModal, {
        params: { venueKey },
      })
    } else {
      const createUpdatePromise =
        (isEdit && emailCampaign) || newCampaignId.current
          ? updateEmailCampaignsQuery({
              emailCampaignIds: emailCampaign ? [emailCampaign.id] : [newCampaignId.current],
              emailCampaignUpdate: campaignData,
              venueId,
            })
          : createEmailCampaign({ emailCampaign: campaignData, venueId })
      try {
        setIsSaving(true)
        const updateResponse = await createUpdatePromise.unwrap()
        notify({
          content: formatMessage(campaignBuilderMessages.saveCampaignSuccessMessage),
          type: 'success',
        })
        if (shouldExit.current) {
          nav.push(routes.manager2.marketing.oneTimeEmailCenter, {
            params: { venueKey },
            queryMode: 'clear',
          })
        }
        if (!isEdit && !newCampaignId.current && !shouldExit.current) {
          const response = updateResponse as EmailCampaignResult
          newCampaignId.current = response.emailCampaign?.id ?? ''
          if (isDuplicate) {
            nav.push(routes.manager2.marketing.oneTimeEmailCenter.editOneTimeEmail, {
              params: { venueKey, campaignId: newCampaignId.current },
              queryMode: 'clear',
            })
          }
        }
        reset({}, { keepValues: true })
        setIsSaving(false)
      } catch {
        notify({
          content: formatMessage(campaignBuilderMessages.saveCampaignErrorMessage),
          type: 'error',
        })
      }
    }
  }

  const getRecipientTagNames = useCallback(
    () =>
      formatList(
        (form.getValues().recipientAutotags || []).map(autoTag => getAutoTagName(autoTag, autoTags) || ''),
        { style: 'long', type: 'conjunction' }
      ),
    [autoTags, form, formatList]
  )

  const onSendConfirm = useCallback(async () => {
    const campaignData = packOneTimeEmailBuilderFormData(getValues())
    const campaignId = isDuplicate ? undefined : emailCampaign?.id
    const recipientTagNames = getRecipientTagNames()
    const isScheduledCampaign = campaignData?.oneTimeSendTimeConfig.sendType === MarketingCampaignOneTimeSendTypeEnum.SCHEDULED
    const successMessage = isScheduledCampaign
      ? formatMessage(campaignBuilderMessages.scheduleSuccess, {
          campaign: campaignData?.campaignName,
          date: campaignData?.oneTimeSendTimeConfig.date || '',
          time: TIMES_CHOICES[TIMES_CHOICES.findIndex(o => o.id === campaignData?.oneTimeSendTimeConfig.timeOfDay)]?.label || '',
        })
      : formatMessage(campaignBuilderMessages.sendSuccess, { campaign: campaignData?.campaignName, recipientTagNames })

    const createUpdatePromise = campaignId
      ? updateEmailCampaignsQuery({ emailCampaignIds: [campaignId], emailCampaignUpdate: campaignData, venueId: venueId || '' })
      : createEmailCampaign({ emailCampaign: campaignData, venueId })

    nav.closeSurface(
      nav.closeSurfaceHref(routes.manager2.marketing.oneTimeEmailCenter.confirmSendModal, {
        params: { venueKey },
      })
    )

    try {
      await createUpdatePromise.unwrap()

      notify({
        content: successMessage,
        type: 'success',
      })
      window.location.assign(
        nav.href(routes.manager2.marketing.oneTimeEmailCenter, {
          params: { venueKey },
          queryMode: 'clear',
        })
      )
    } catch {
      notify({
        content: formatMessage(campaignBuilderMessages.saveCampaignErrorMessage),
        type: 'error',
      })
    }
  }, [
    createEmailCampaign,
    emailCampaign?.id,
    formatMessage,
    getRecipientTagNames,
    getValues,
    isDuplicate,
    nav,
    packOneTimeEmailBuilderFormData,
    updateEmailCampaignsQuery,
    venueId,
    venueKey,
  ])

  if (!venue) {
    return null
  }

  return (
    <>
      <SettingsPageMeta
        venue={venue.name}
        title={formatMessage(isEdit ? emailBuilderMessages.editEmailCampaign : emailBuilderMessages.createEmailCampaign)}
      />
      <Form
        {...form}
        onSubmit={async data => {
          if (shouldUseTopol) {
            await saveTopol()
            await trigger()

            trySaveEmailCampaign(getValues())
          } else {
            trySaveEmailCampaign(data)
          }
        }}
        onInvalid={() => {}}
        innerRef={formRef}
      >
        <SettingsPageContent
          title={formatMessage(isEdit ? emailBuilderMessages.editOneTimeEmail : emailBuilderMessages.createOneTimeEmail)}
          headerWidth="calc(100% - 274px)"
          secondHeaderMaxWidth="968px"
          actions={
            <HStack spacing="s">
              <Button
                variant="tertiary"
                data-test="sr-one-time-email-builder-cancel-button"
                onClick={() => {
                  setIsCancelled(true)
                  if (isDirty) {
                    goToOffers.current = false
                    nav.push(routes.manager2.marketing.emailCenter.emails.emailBuilder.cancelEmailEditModal, {
                      params: { venueKey: venue.urlKey },
                    })
                  } else {
                    window.location.assign(
                      nav.href(routes.manager2.marketing.oneTimeEmailCenter, { params: { venueKey: venue.urlKey } }).split('?')[0] || ''
                    )
                  }
                }}
                disabled={isActionDisabled}
              >
                {formatMessage(commonMessages.cancel)}
              </Button>

              <ActionsButton
                data-test="sr-one-time-email-builder-save-as-draft-button"
                variant="secondary"
                type="submit"
                disabled={isActionDisabled}
                actions={[
                  {
                    id: 'saveDraft',
                    title: formatMessage(commonMessages.saveDraft),
                    onClick: () => {
                      shouldExit.current = false
                      setValue('campaignState', OneTimeEmailCampaignStatusEnum.DRAFT, { shouldDirty: false })
                    },
                  },
                  {
                    id: 'saveAndExit',
                    title: formatMessage(commonMessages.saveAndExit),
                    onClick: () => {
                      shouldExit.current = true
                      setValue('campaignState', OneTimeEmailCampaignStatusEnum.DRAFT, { shouldDirty: false })
                      formRef?.current?.requestSubmit()
                    },
                  },
                ]}
              />
              <Button
                data-test="sr-one-time-email-builder-save-button"
                variant="primary"
                type="submit"
                onClick={() => {
                  setValue('campaignState', OneTimeEmailCampaignStatusEnum.ACTIVE, { shouldDirty: false })
                }}
                disabled={isActionDisabled}
              >
                {formatMessage(
                  form.watch().scheduleType === MarketingCampaignOneTimeSendTypeEnum.IMMEDIATELY
                    ? campaignBuilderMessages.send
                    : campaignBuilderMessages.schedule
                )}
              </Button>
            </HStack>
          }
        >
          <Box p="lm" width="100%">
            <VStack spacing="lm">
              <EmailDetails
                autoFocus
                field={field as unknown as EmailDetailsFormField}
                data-test="sr-one-time-email-builder-details-section"
              />
              <Audience
                campaignContent={emailCampaign}
                venueProfile={venueProfile}
                field={field as unknown as AudienceFormField}
                venue={venue}
                isOneTimeCampaign
                showAudienceSizeBanner
                messages={emailBuilderMessages}
                data-test="sr-one-time-email-builder-audience-section"
              />
              <OneTimeSendingSchedule
                field={field as unknown as OneTimeSendingScheduleFormField}
                data-test="sr-one-time-email-builder-sending-schedule-section"
              />
              <EmailContentFromScratch
                fields={field as unknown as EmailContentFormField}
                data-test="sr-one-time-email-builder-content-section"
                imageUpload
                venueProfile={venueProfile}
                experiencesData={experiencesData}
                isDirty={isDirty}
                setGoToOffers={setGoToOffers}
                shouldUseTopol={shouldUseTopol}
                template={defaultValues?.topolTemplate}
                groupVenues={groupVenues}
              />
            </VStack>
          </Box>
        </SettingsPageContent>
      </Form>
      <Surface destination={routes.manager2.marketing.oneTimeEmailCenter.confirmSendModal}>
        <Window>
          <ConfirmSendEmailModal
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.oneTimeEmailCenter.confirmSendModal, {
              params: { venueKey: venue.urlKey },
            })}
            recipientTagNames={getRecipientTagNames()}
            onCancel={() => setIsSaving(false)}
            onConfirm={onSendConfirm}
            campaignData={packOneTimeEmailBuilderFormData(getValues())}
            disabled={isActionDisabled}
          />
        </Window>
      </Surface>
      <Surface destination={routes.manager2.marketing.emailCenter.emails.emailBuilder.sendTestEmailModal}>
        <Window>
          <SendTestEmailModal
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.emailCenter.emails.emailBuilder.sendTestEmailModal, {
              params: { venueKey: venue.urlKey },
            })}
            signoff=""
            subjectLine={getPlainTextFromHtml(getValues().subjectLine.value ?? '')}
            senderAddress={getPlainTextFromHtml(getValues().emailSender.value ?? '')}
            replyToAddress={getValues().replyToAddress}
            previewText={getValues().emailPreview.value}
            template={generateEmailCampaignPreviewTemplate('', getValues().emailBodySections[0]?.value ?? '', '', '', '')}
            emailBody={getValues().emailBodySections[0]?.value ?? ''}
            emailGreeting=""
            footer=""
            signature=""
            venueId={venueId}
            campaignType={MarketingCampaignTypeEnum.ONE_TIME}
            withPreview={false}
            onConfirm={setCampaignStateActive}
            onValidate={() =>
              trigger(['campaignName', 'replyToAddress', 'emailSender', 'subjectLine.value', 'emailBodySections'], {
                shouldFocus: true,
              })
            }
          />
        </Window>
      </Surface>
      <Surface destination={routes.manager2.marketing.emailCenter.emails.emailBuilder.cancelEmailEditModal}>
        <Window>
          <CancelEmailEditModal
            onCancel={() => setIsCancelled(false)}
            onConfirm={() => setIsCancelled(true)}
            closeHref={cancelModalCloseHref}
            isOneTimeEmail
            isFromTemplate={!!parentTemplate}
            goToOffers={goToOffers.current}
          />
        </Window>
      </Surface>
    </>
  )
}

const getAutoTagName = (autoTag: TagOption | undefined, autoTags: GenericTagGroup[] | undefined) => {
  const category = autoTags?.find(category => category.id === autoTag?.categoryId)

  return autoTag?.label && (category?.tagNameDisplays?.[autoTag?.label] || autoTag?.label)
}
