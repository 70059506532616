import { defineMessages } from '@sevenrooms/core/locales'

export const marketingUpsellMessages = defineMessages({
  smsMarketingLead: {
    id: 'marketingUpsellMessages.smsMarketingLead',
    defaultMessage: 'Send text messages guests will crave.',
  },
  smsMarketingStat0: {
    id: 'marketingUpsellMessages.smsMarketingStat0',
    defaultMessage: 'Open Rate',
  },
  smsMarketingStat1: {
    id: 'marketingUpsellMessages.smsMarketingStat1',
    defaultMessage: 'Revenue per Text<footnote_idx>',
  },
  smsMarketingStat1Footnote0: {
    id: 'marketingUpsellMessages.smsMarketingStat1Footnote0',
    defaultMessage:
      'Revenue per Text: (Total Reservation Revenue generated by Beta Customers) divided by (Total Number of Messages sent by Beta Customers)',
  },
  smsMarketingStat2: {
    id: 'marketingUpsellMessages.smsMarketingStat2',
    defaultMessage: 'ROI<footnote_idx> for Beta Customers',
  },
  smsMarketingStat2Footnote0: {
    id: 'marketingUpsellMessages.smsMarketingStat2Footnote0',
    defaultMessage:
      'ROI: (Total Reservation Revenue generated by Beta Customers) divided by (Total Message Costs. Total Message Cost = Cost per Message * Total Number of Messages sent by Beta Customers)',
  },
  smsMarketingBullets: {
    id: 'marketingUpsellMessages.smsMarketingBullets',
    defaultMessage:
      '<li>Send personalized Text Marketing campaigns at scale, segmented by guest data.</li>' +
      '<li>Create and send campaigns in minutes with data-backed hospitality templates that are proven to convert.</li>' +
      '<li>Measure the impact of text campaigns with a real-time view of the revenue, reservations, covers, and orders attributed to each campaign.</li>',
  },
  smsMarketingImageAlt: {
    id: 'marketingUpsellMessages.smsMarketingImageAlt',
    defaultMessage: 'Preview of a text message received by a guest, with projected revenue.',
  },
} as const)
