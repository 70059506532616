import moment from 'moment-timezone'
import React from 'react'
import styled from 'styled-components'
import ChannelSpecificDataView from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/ChannelSpecificData'
import StarsDataView from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/StarsData'
import SummaryBar from 'mgr/pages/single-venue/marketing/components/shared/SummaryBar'
import { isSevenroomsChannelsOnly } from 'mgr/pages/single-venue/marketing/utils/data'

const DataContainer = styled.div`
  display: flex;
  padding: 0 ${props => props.theme.gutter.triple};
`

const ReviewsStarsContainer = styled.div`
  display: flex;
  width: 50%;
`

const ReviewsBarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

const Upsell = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 68px;
  justify-content: space-between;
`

const TrialText = styled.div`
  font-size: 15px;
  font-weight: 500;
`

const TrialButton = styled.div`
  cursor: pointer;
  border-radius: 3px;
  padding: 12px 12px 12px 12px;
  background: #0abcc2;
  color: #fff;
  display: block;
  font-size: 12px;
  text-align: center;
`

const UPSELL_STANDALONE_UNCONFIGURED = Object.freeze({
  BANNER_TEXT: 'Haven’t turned on Reservation Feedback emails?',
  BUTTON_TEXT: 'Learn More',
  BUTTON_HREF: 'https://help.sevenrooms.com/hc/en-us/articles/360006202092-Feedback-Emails-Overview-Notifications-and-Settings',
})

const UPSELL_STANDALONE_CONFIGURED = Object.freeze({
  BANNER_TEXT: 'Want to see all of your online reviews here too?',
  BUTTON_TEXT: 'GET A 14-DAY FREE TRIAL',
  BUTTON_HREF:
    'https://sevenrooms.com/en/7x-guest-satisfaction/?channel=productpage_guestsatisfaction&utm_source=espProductPage&utm_medium=display&utm_campaign=7X+free-trial+october2019',
})

class ReviewsSummary extends React.PureComponent {
  render() {
    const {
      overall,
      breakdown,
      channels,
      lastUpdated,
      isLoading,
      pngSrcMap,
      onPlatformFilterChange,
      isFullFeatured,
      isFeedbackConfigured,
      isUpsellDisabled,
    } = this.props

    const hasData = !!parseFloat(overall.average) || channels?.facebook?.total
    const duration = moment.duration(moment().diff(moment.utc(lastUpdated)))
    const hours = Math.round(duration.asHours())
    const additionalMessage = isFullFeatured
      ? !isNaN(hours) && `Reviews last retrieved: ${hours} hours ago`
      : 'Feedback submissions are up to date'
    const upsellConfig = isFeedbackConfigured ? UPSELL_STANDALONE_CONFIGURED : UPSELL_STANDALONE_UNCONFIGURED

    return (
      <SummaryBar
        hasData={hasData}
        title={isFullFeatured && !isSevenroomsChannelsOnly(channels) ? 'Feedback & Reviews' : 'Feedback'}
        isLoading={isLoading}
        additionalMessage={additionalMessage}
      >
        <DataContainer>
          <ReviewsStarsContainer>
            <StarsDataView overall={overall} breakdown={breakdown} />
          </ReviewsStarsContainer>
          <ReviewsBarsContainer>
            <ChannelSpecificDataView
              onPlatformFilterChange={onPlatformFilterChange}
              channels={channels}
              isFullFeatured={isFullFeatured}
              pngSrcMap={pngSrcMap}
            />
            {!isUpsellDisabled && !isFullFeatured && (
              <Upsell>
                <TrialText>{upsellConfig.BANNER_TEXT}</TrialText>
                <TrialButton
                  onClick={() => {
                    window.open(upsellConfig.BUTTON_HREF, '_blank')
                  }}
                >
                  {upsellConfig.BUTTON_TEXT}
                </TrialButton>
              </Upsell>
            )}
          </ReviewsBarsContainer>
        </DataContainer>
      </SummaryBar>
    )
  }
}

export default ReviewsSummary
