import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button, Checkbox, Label } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex, Grid, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { referralsMessages } from '../../locales'
import { ReferredPerkPreview } from '../ReferredPerkPreview'
import type { ReferredClientEmailConfigurationsFormFields } from './ReferredClientEmailConfigurationsForm.zod'

export interface ReferredClientEmailConfigurationsFormProps {
  disabled: boolean
  fields: ReferredClientEmailConfigurationsFormFields
  referredPerkText: string
  isNewClient: boolean
}

export function ReferredClientEmailConfigurationsForm({
  fields,
  disabled,
  referredPerkText,
  isNewClient,
}: ReferredClientEmailConfigurationsFormProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueKey } = useVenueContext()

  return (
    <Grid gap="lm" gridTemplateColumns="6fr 6fr">
      <VStack spacing="s">
        <Label
          htmlFor={fields.prop('bookingConfirmationEmail').name}
          primary={
            <Text fontWeight="bold">
              {isNewClient
                ? formatMessage(referralsMessages.referredNewClientEmailConfigurationsTitle)
                : formatMessage(referralsMessages.referredExistingClientEmailConfigurationsTitle)}
            </Text>
          }
          secondary={
            isNewClient
              ? formatMessage(referralsMessages.referredNewClientEmailConfigurationsDescription)
              : formatMessage(referralsMessages.referredExistingClientEmailConfigurationsDescription)
          }
          info={disabled ? <>{formatMessage(referralsMessages.referralProgramDisabledCommunications)}</> : undefined}
          tooltipDisplayAction="click"
        />
        <VStack spacing="s">
          <Checkbox field={fields.prop('bookingConfirmationEmail')} disabled={disabled}>
            {formatMessage(referralsMessages.bookingConfirmationEmail)}
          </Checkbox>
          <Checkbox field={fields.prop('bookingReminderEmail')} disabled={disabled}>
            {formatMessage(referralsMessages.bookingReminderEmail)}
          </Checkbox>
        </VStack>
      </VStack>

      <VStack spacing="xs">
        <Flex justifyContent="space-between">
          <Box>
            <Text fontWeight="bold">
              {isNewClient
                ? formatMessage(referralsMessages.referredNewClientPreviewText)
                : formatMessage(referralsMessages.referredExistingClientPreviewText)}
            </Text>
          </Box>
          <Box>
            <Button
              variant="tertiary"
              href={nav.href(routes.manager2.settings.emails.reservationConfirmation, {
                params: { venueKey },
              })}
              target="_blank"
              noPadding
              data-test="customize-text-button"
            >
              <Box mr="s">
                <Icon name="VMSWeb-open-in-new" />
              </Box>
              {formatMessage(referralsMessages.customizeText)}
            </Button>
          </Box>
        </Flex>

        <ReferredPerkPreview referredPerkText={referredPerkText} />
      </VStack>
    </Grid>
  )
}
