import _ from 'lodash'
import { useEffect } from 'react'
import { useFormContext, useWatch, type Field } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Link, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { useTheme } from '@sevenrooms/core/ui-kit'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { ErrorMessage, FormMultiSelect, Label, TextArea, FormNumberInput } from '@sevenrooms/core/ui-kit/form'
import { Box, Grid, Tooltip, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Tag, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { referralsMessages } from '../../locales'
import type { ReferralLevelFormFields } from './ReferralLevelForm.zod'

export interface ReferralLevelFormProps {
  disabled: boolean
  fields: ReferralLevelFormFields
  globalPerks: SelectOption[]
  localPerks: SelectOption[]
  level: number
}

export function ReferralLevelForm({ level, fields, disabled, globalPerks, localPerks }: ReferralLevelFormProps) {
  const vxTheme = useTheme()
  const nav = useNavigation()
  const { venueKey } = useVenueContext()
  const { formatMessage } = useLocales()
  const { trigger } = useFormContext()
  const tag = useWatch(fields.prop('name'))
  const colorHex = useWatch(fields.prop('colorHex'))
  const conditionThreshold = fields.prop('conditionThreshold')
  const conditionThresholdData = useWatch(conditionThreshold)
  const { error: conditionThresholdError } = conditionThreshold.getFieldState()
  const noGlobalPerks = _.isEmpty(globalPerks)
  const noLocalPerks = _.isEmpty(localPerks)

  useEffect(() => {
    trigger()
  }, [conditionThresholdData, trigger])

  const renderGlobalPerks = () => (
    <Box gridRowStart={2} maxWidth="450px">
      <FormMultiSelect
        data-test={`global-perks-${level}`}
        options={globalPerks}
        field={fields.prop('globalPerks') as unknown as Field<string[]>}
        scrollLock
        disabled={disabled || noGlobalPerks}
        usePills={false}
        useCheckboxItems
        searchable
        showRemoveAllButton
        placeholder={formatMessage(referralsMessages.referralSearchPerks)}
      />
    </Box>
  )

  const renderLocalPerks = () => (
    <Box gridRowStart={3} maxWidth="450px">
      <FormMultiSelect
        data-test={`local-perks-${level}`}
        options={localPerks}
        field={fields.prop('localPerks')}
        scrollLock
        disabled={disabled || noLocalPerks}
        usePills={false}
        useCheckboxItems
        searchable
        showRemoveAllButton
        placeholder={formatMessage(referralsMessages.referralSearchPerks)}
      />
    </Box>
  )

  return (
    <Grid gridTemplateColumns="4fr 8fr" gridTemplateRows="repeat(5, min-content)" alignItems="start" columnGap="lm" rowGap="m">
      <Box gridColumnStart={1} gridRowStart={1}>
        <Label secondary={formatMessage(referralsMessages.referralAutoTag)} htmlFor={`condition-threshold-${level}`} />
      </Box>

      <Box gridColumnStart={1} gridRowStart={2}>
        <Label secondary={formatMessage(referralsMessages.referralGlobalPerks)} htmlFor={`global-perks-${level}`} />
      </Box>

      <Box gridColumnStart={1} gridRowStart={3}>
        <Label secondary={formatMessage(referralsMessages.referralLocalPerks)} htmlFor={`local-perks-${level}`} />
      </Box>

      <Box gridColumnStart={1} gridRowStart={4}>
        <Label
          secondary={formatMessage(referralsMessages.referralAdditionalNotes)}
          secondaryInfo={<>{formatMessage(referralsMessages.additionalNotesInfo)}</>}
          htmlFor={`additional-notes-${level}`}
        />
      </Box>

      <VStack gridColumnStart={2} spacing="xs" alignItems="start" gridRowStart={1}>
        <Tag backgroundColor={colorHex ?? vxTheme.colors.defaultTagColor} icon="VMSWeb-refresh">
          {tag}
        </Tag>

        <Box>
          <Text>{formatMessage(referralsMessages.referralConditionStart)}&nbsp;</Text>
          <Box width="60px" display="inline-block">
            <FormNumberInput
              data-test={`sr-condition-threshold-${level}`}
              id={`condition-threshold-${level}`}
              field={fields.prop('conditionThreshold')}
              disabled={disabled}
              hideErrorMessage
            />
          </Box>

          <Text>&nbsp;{formatMessage(referralsMessages.referralConditionEnd)}</Text>

          {conditionThresholdError && (
            <Box mt="s">
              <ErrorMessage>{conditionThresholdError?.message}</ErrorMessage>
            </Box>
          )}
        </Box>
      </VStack>

      {noGlobalPerks ? (
        <Tooltip
          displayAction="hover"
          content={
            <>
              {formatMessage(referralsMessages.referralNoGlobalPerks, {
                link: (
                  <Link to={nav.href(routes.manager2.marketing.perks2, { params: { venueKey } })}>
                    {_.toLower(formatMessage(commonMessages.here))}
                  </Link>
                ),
              })}
            </>
          }
        >
          {renderGlobalPerks()}
        </Tooltip>
      ) : (
        renderGlobalPerks()
      )}

      {noLocalPerks ? (
        <Tooltip
          displayAction="hover"
          content={
            <>
              {formatMessage(referralsMessages.referralNoLocalPerks, {
                link: (
                  <Link to={nav.href(routes.manager2.marketing.perks2, { params: { venueKey } })}>
                    {_.toLower(formatMessage(commonMessages.here))}
                  </Link>
                ),
              })}
            </>
          }
        >
          {renderLocalPerks()}
        </Tooltip>
      ) : (
        renderLocalPerks()
      )}

      <Box gridRowStart={4} maxWidth="450px">
        <TextArea
          data-test={`sr-additional-notes-${level}`}
          id={`additional-notes-${level}`}
          field={fields.prop('additionalNotes')}
          disabled={disabled}
          rows={1}
          resize="none"
        />
      </Box>
    </Grid>
  )
}
