import React from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { ExperiencesLocales } from './Experiences.locales'

interface ExperiencesActionButtonsProps {
  disabled: boolean
  onCancel: () => void
  experienceId?: string
  onDeactivate: () => void
  onSaveAndPublish: () => void
  onSaveAsDraft: () => void
  setStayInPage: (stayInPage: boolean) => void
  isActive?: boolean
  isInActive?: boolean
}

export function ExperiencesActionButtons({
  disabled,
  onCancel,
  experienceId,
  isActive,
  isInActive,
  setStayInPage,
  onDeactivate,
  onSaveAsDraft,
  onSaveAndPublish,
}: ExperiencesActionButtonsProps) {
  const { formatMessage } = useLocales()

  return (
    <HStack spacing="s">
      <Button data-test="experience-form-cancel" disabled={disabled} variant="tertiary" onClick={onCancel}>
        {formatMessage(commonMessages.cancel)}
      </Button>
      {isActive ? (
        <>
          <Button
            data-test="experience-form-deactivate"
            disabled={disabled}
            variant="secondary-warning"
            onClick={() => {
              setStayInPage(false)
              onDeactivate()
            }}
            type="submit"
          >
            {formatMessage(commonMessages.deactivate)}
          </Button>
          <Button
            data-test="experience-form-save-and-publish"
            disabled={disabled}
            variant="primary"
            onClick={() => {
              setStayInPage(true)
              onSaveAndPublish()
            }}
            type="submit"
          >
            {formatMessage(commonMessages.saveChanges)}
          </Button>
        </>
      ) : (
        <>
          {experienceId && isInActive ? (
            <Button
              data-test="experience-form-save"
              disabled={disabled}
              variant="secondary"
              type="submit"
              onClick={() => {
                setStayInPage(true)
                onDeactivate()
              }}
            >
              {formatMessage(commonMessages.saveChanges)}
            </Button>
          ) : (
            <Button
              data-test="experience-form-save"
              disabled={disabled}
              variant="secondary"
              type="submit"
              onClick={() => {
                setStayInPage(!!experienceId)
                onSaveAsDraft()
              }}
            >
              {experienceId ? formatMessage(commonMessages.saveChanges) : formatMessage(commonMessages.saveAsDraft)}
            </Button>
          )}
          <Button
            data-test="experience-form-save-and-publish"
            disabled={disabled}
            variant="primary"
            onClick={() => {
              setStayInPage(false)
              onSaveAndPublish()
            }}
            type="submit"
          >
            {experienceId ? formatMessage(commonMessages.saveAndPublish) : formatMessage(ExperiencesLocales.publishOffer)}
          </Button>
        </>
      )}
    </HStack>
  )
}
