import AddIcon from '@mui/icons-material/Add'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { BaseSection, Box } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { Button } from '@sevenrooms/react-components/components/Button'
import { vmsTheme, ThemeProvider } from '@sevenrooms/react-components/components/ThemeProvider'
import { PerksTable } from '../../components/PerksTable'
import { perksMessages } from '../../locales'

export interface PerksProps {}

export function Perks() {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()

  if (!venue) {
    return null
  }

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(perksMessages.perks)} />

      <SettingsPageContent
        headerWidth="calc(100% - 274px)"
        secondHeaderMaxWidth="100%"
        title={formatMessage(perksMessages.perks)}
        description={formatMessage(perksMessages.perksDescription)}
        actions={
          <ThemeProvider theme={vmsTheme}>
            <Button
              data-test="settings-perk-create-perk-button"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                nav.push(routes.manager2.marketing.perks2.create, {
                  params: { venueKey: venue.urlKey },
                })
              }}
            >
              {formatMessage(perksMessages.createNewPerk)}
            </Button>
          </ThemeProvider>
        }
      >
        <Box pl="lm" pr="lm" mb="xxl" width="100%">
          <BaseSection maxWidth="100%">
            <PerksTable />
          </BaseSection>
        </Box>
      </SettingsPageContent>
    </>
  )
}
