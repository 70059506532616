import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { oneTimeEmailCenterMessages } from '../../locales'

interface EmailTemplatePreviewFooterProps {
  close?: () => void
  templateId: string
}

export function EmailTemplatePreviewFooter({ templateId, close }: EmailTemplatePreviewFooterProps) {
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()

  const selectTemplate = () => {
    nav.push(routes.manager2.marketing.oneTimeEmailCenter.createOneTimeEmail, {
      params: { venueKey: venue.urlKey },
      query: { campaignToDuplicateId: '', parentTemplateId: templateId },
    })
  }

  return (
    <HStack spacing="s" ml="auto">
      <Button data-test="ui-kit-slideout-cancel" onClick={close} variant="tertiary">
        {formatMessage(commonMessages.cancel)}
      </Button>
      <Button data-test="ui-kit-slideout-primary-button" onClick={selectTemplate} variant="primary">
        {formatMessage(oneTimeEmailCenterMessages.useTemplate)}
      </Button>
    </HStack>
  )
}
