import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import ExperienceTemplates from '@sevenrooms/mgr-marketing-templates-gallery/assets/experiences/config.json'
import { messages } from '@sevenrooms/mgr-marketing-templates-gallery/locales/marketingTemplatesGallery.locales'
import { MarketingTemplatesCard, MarketingTemplatesCarousel } from '../MarketingTemplatesCarousel'
import { GetTemplateImages } from './utils'

interface MarketingTemplatesGalleryProps {
  isExperienceTemplatesEnabled: boolean | undefined
}

interface TemplateProps {
  templateName: string
  templateTeaser: string
  offerImage: string
  [key: string]: string
}

export function MarketingTemplatesGallery({ isExperienceTemplatesEnabled }: MarketingTemplatesGalleryProps) {
  const { formatMessage } = useLocales()

  if (!isExperienceTemplatesEnabled) {
    return <></>
  }

  const templates: TemplateProps[] = ExperienceTemplates
  const templateImages = GetTemplateImages()

  return (
    <VStack data-test="templates-carousel" spacing="m" pb="lm" pt="m">
      <VStack spacing="s">
        <HStack spacing="xs">
          <StatusLabel variant="success">{formatMessage(messages.statusLabelText)}</StatusLabel>
          <Text textStyle="h2">{formatMessage(messages.createExperienceTitle)}</Text>
        </HStack>
        <Text textStyle="body1" color="secondaryFont">
          {formatMessage(messages.createExperienceDescription)}
        </Text>
      </VStack>
      <MarketingTemplatesCarousel>
        {templates.map(item => (
          <MarketingTemplatesCard
            templateName={item.templateName}
            templateTeaser={item.templateTeaser}
            offerImage={templateImages[item.offerImage as keyof typeof templateImages]}
            buttonText={formatMessage(messages.useTemplateButtonText)}
            onClick={() => {}}
            key={`template-${templates.indexOf(item)}}`}
          />
        ))}
      </MarketingTemplatesCarousel>
    </VStack>
  )
}
