import { useMemo } from 'react'
import { useGetVenueTaxQuery } from '@sevenrooms/core/api'
import type { AccessRule, Experience } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, FormNumberInput, FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, HStack, Loader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { ExperiencesLocales } from '../../Experiences.locales'
import { ExperienceAccessRules } from '../ExperienceAccessRules/ExperienceAccessRules'
import { Description } from './Description'
import { Details } from './Details'
import { MinimumSpendMatrixComponent } from './MinSpendMatrix'
import type { CreateEditExperienceFormData } from '../../CreateEditExperienceForm.zod'

interface PDRProps {
  experience?: Experience
  accessRuleData?: AccessRule[]
  field: Field<CreateEditExperienceFormData>
  isTripleSeatDinewiseEnabled: boolean
}

export function PDR({ experience, accessRuleData, field, isTripleSeatDinewiseEnabled }: PDRProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()

  const { data: taxes, isFetching: isFetchingTaxes } = useGetVenueTaxQuery({ venueId })

  const taxesOptions = useMemo(() => taxes?.map(tax => ({ id: tax.id, label: `${tax.name} [${tax.value}%]` })) ?? [], [taxes])

  return isFetchingTaxes ? (
    <Loader />
  ) : (
    <>
      <Details field={field} />

      <Description field={field} />

      <BaseSection title={formatMessage(ExperiencesLocales.pdrFeesBoxTitle)}>
        <HStack spacing="lm" p="lm">
          <Label primary={formatMessage(ExperiencesLocales.pdrAdminFee)}>
            <FormNumberInput max={100} field={field.prop('pdrDetails.pdrAdminFee')} />
          </Label>
          <Label primary={formatMessage(ExperiencesLocales.pdrTaxRateId)}>
            <FormSelect field={field.prop('pdrDetails.pdrTaxRateId')} options={taxesOptions} withEmpty />
          </Label>
        </HStack>
      </BaseSection>

      <BaseSection title={formatMessage(ExperiencesLocales.pdrMinimumSpendMatrixBaseBoxTitle)}>
        <VStack spacing="lm" p="lm">
          <Label
            primary={formatMessage(ExperiencesLocales.pdrDefaultMinSpend)}
            secondary={formatMessage(ExperiencesLocales.pdrDefaultMinSpendHelper)}
          >
            <FormNumberInput field={field.prop('pdrDetails.pdrMinSpend')} greaterThanZero fullWidth />
          </Label>
          <MinimumSpendMatrixComponent field={field.prop('pdrDetails.pdrMinimumSpendMatrix')} />
        </VStack>
      </BaseSection>

      <ExperienceAccessRules
        experience={experience}
        accessRuleData={accessRuleData}
        unlinkedAccessRuleIDsField={field.prop('unlinkedAccessRuleIDs')}
        linkedAccessRuleIDsField={field.prop('linkedAccessRuleIDs')}
      />

      {isTripleSeatDinewiseEnabled ? (
        <BaseSection title={formatMessage(ExperiencesLocales.pdrTripleSeatIntegrationBoxTitle)}>
          <VStack spacing="lm" p="lm">
            <Label primary={formatMessage(ExperiencesLocales.pdrTripleSeatRoomId)} />
            <FormInput field={field.prop('pdrDetails.pdrTripleSeatRoomId')} />
          </VStack>
        </BaseSection>
      ) : null}
    </>
  )
}
