import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  useTemplateButtonText: {
    id: 'marketingTemplatesLocales.useTemplateButtonText',
    defaultMessage: 'Use Template',
  },
  createExperienceTitle: {
    id: 'marketingTemplatesLocales.createExperienceTitle',
    defaultMessage: 'Create an Experience from a Template',
  },
  createExperienceDescription: {
    id: 'marketingTemplatesLocales.createExperienceDescription',
    defaultMessage: 'Use the new wizard to quickly create a new experience designed to drive demand',
  },
  statusLabelText: {
    id: 'marketingTemplatesLocales.statusLabelText',
    defaultMessage: 'New',
  },
})
