import { useMemo } from 'react'
import type { VenueProfile } from '@sevenrooms/core/domain'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'

export type SocialMediaFormData = ZodSchema<ReturnType<typeof useSocialMediaForm>>
export type SocialMediaFormFields = Field<SocialMediaFormData>
export type SocialMediaFieldKey = 'facebookLink' | 'instagramLink' | 'snapchatLink' | 'twitterLink' | 'tiktokLink'

const urlFieldOptional = z.string().url().nullable().or(z.literal(''))

export const useSocialMediaForm = () =>
  useMemo(
    () =>
      z.object({
        facebookLink: urlFieldOptional,
        instagramLink: urlFieldOptional,
        snapchatLink: urlFieldOptional,
        twitterLink: urlFieldOptional,
        tiktokLink: urlFieldOptional,
      }),
    []
  )

export const getSocialMediaFormDefaultValues = (source: VenueProfile): SocialMediaFormData => ({
  facebookLink: source.facebookLink,
  instagramLink: source.instagramLink,
  snapchatLink: source.snapchatLink,
  twitterLink: source.twitterLink,
  tiktokLink: source.tiktokLink,
})
