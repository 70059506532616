import { parsePhoneNumber } from 'libphonenumber-js'
import { useMemo } from 'react'
import { SMSPhonePurpose, useGetVenuePhoneNumbersQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Redirect, Route, Switch, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { oneTimeSMSCenterMessages } from '../locales'
import { OneTimeSMS } from '../OneTimeSMS'

export function OneTimeSMSCampaignCenter() {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()

  const { data, isFetching } = useGetVenuePhoneNumbersQuery({
    venueId: venue.id || '',
    purpose: SMSPhonePurpose.MARKETING,
  })

  const formattedPhoneNumber = useMemo(() => {
    if (!data?.results?.length) {
      return ''
    }
    try {
      return parsePhoneNumber(data?.results[0]?.smsPhoneNumber || '').format('NATIONAL')
    } catch (_) {
      return data?.results[0]?.smsPhoneNumber || ''
    }
  }, [data])

  if (!venue) {
    return null
  }

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(oneTimeSMSCenterMessages.oneTimeSMSCenterTitle)} />

      <SettingsPageContent
        headerWidth="calc(100% - 274px)"
        secondHeaderMaxWidth="100%"
        title={formatMessage(oneTimeSMSCenterMessages.oneTimeSMSCenterTitle)}
        description={
          isFetching || !formattedPhoneNumber
            ? formatMessage(oneTimeSMSCenterMessages.oneTimeSMSCenterDescription)
            : formatMessage(oneTimeSMSCenterMessages.oneTimeSMSCenterDescriptionWithPhoneNumber, { phone_number: formattedPhoneNumber })
        }
        actions={
          <Button
            data-test="one-time-sms-center-create-sms-button"
            variant="primary"
            icon="VMSWeb-add"
            onClick={() => {
              nav.push(routes.manager2.marketing.oneTimeSMSCenter.smsCampaignTemplates, {
                params: { venueKey: venue.urlKey },
              })
            }}
          >
            {formatMessage(oneTimeSMSCenterMessages.oneTimeSMSCenterNewSMS)}
          </Button>
        }
      >
        <Box pl="lm" pr="lm" mb="xxl" width="100%">
          <Switch>
            <Route path={routes.manager2.marketing.oneTimeSMSCenter.path}>
              <OneTimeSMS />
            </Route>
            <Redirect to={routes.manager2.marketing.oneTimeSMSCenter.path} />
          </Switch>
        </Box>
      </SettingsPageContent>
    </>
  )
}
