import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button, Checkbox, Label } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex, Grid, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { referralsMessages } from '../../locales'
import { ReferralCardPreview } from '../ReferralCardPreview'
import type { ReferrersEmailConfigurationsFormFields } from './ReferrersEmailConfigurationsForm.zod'

export interface ReferralEmailConfigurationsFormProps {
  fields: ReferrersEmailConfigurationsFormFields
  referralLinkSubject: string
  referralLinkBody: string
  referralLink: string
}

export function ReferrersEmailConfigurationsForm({ fields, ...props }: ReferralEmailConfigurationsFormProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueKey } = useVenueContext()

  return (
    <Grid gap="lm" gridTemplateColumns="6fr 6fr">
      <VStack spacing="s">
        <Box>
          <Label
            htmlFor={fields.prop('bookingConfirmationEmail').name}
            primary={<Text fontWeight="bold">{formatMessage(referralsMessages.referrersEmailConfigurationsTitle)}</Text>}
            tooltipDisplayAction="click"
          />
        </Box>

        <VStack spacing="s">
          <Checkbox field={fields.prop('bookingConfirmationEmail')}>{formatMessage(referralsMessages.bookingConfirmationEmail)}</Checkbox>
          <Checkbox field={fields.prop('feedbackEmail')}>{formatMessage(referralsMessages.feedbackEmail)}</Checkbox>
          <Checkbox field={fields.prop('feedbackSuccessPage')}>{formatMessage(referralsMessages.feedbackSuccessPage)}</Checkbox>
        </VStack>
      </VStack>

      <VStack spacing="xs">
        <Flex justifyContent="space-between">
          <Box>
            <Text fontWeight="bold">{formatMessage(commonMessages.preview)}</Text>
          </Box>

          <Box>
            <Button
              variant="tertiary"
              href={nav.href(routes.manager2.settings.emails.reservationConfirmation, {
                params: { venueKey },
              })}
              target="_blank"
              noPadding
              data-test="customize-text-button"
            >
              <Box mr="s">
                <Icon name="VMSWeb-open-in-new" />
              </Box>
              {formatMessage(referralsMessages.customizeText)}
            </Button>
          </Box>
        </Flex>

        <ReferralCardPreview {...props} />
      </VStack>
    </Grid>
  )
}
