import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useMemo } from 'react'
import { useGetAccessRulesQuery, useGetClientTagGroupsQuery, useGetPerkQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation, useParams } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Formats, legacyAdapter } from '@sevenrooms/core/timepiece'
import { Loader, VStack, notify, Box } from '@sevenrooms/core/ui-kit/layout'
import { flattenAudience } from '@sevenrooms/mgr-access-rules-slideout/components/BookingChannels/BookingChannels.zod'
import { useAccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { perksMessages } from '../../locales'
import { CreateEditEarlyAccessPerkForm } from './CreateEditEarlyAccessPerkForm'

const EARLY_ACCESS_AUDIENCE_HIERARCHY_NAME = 'Early Access'

export function CreateEditEarlyAccessPerk() {
  const today = legacyAdapter({ format: Formats.iso, value: new Date().toISOString() }, Formats.MonthDayYearByDash)

  const { perkId } = useParams<{ perkId?: string }>()
  const { venue, venueId } = useVenueContext()
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  const { data: accessRulesByDay, isLoading: isAccessRulesLoading } = useGetAccessRulesQuery({ startDate: today, venueId })
  const { data: perk, isFetching: isFetchingPerk, isError: isErrorFetchingPerk } = useGetPerkQuery(perkId ? { venueId, perkId } : skipToken)
  const { data: clientTagGroups, isLoading: isClientTagGroupsLoading } = useGetClientTagGroupsQuery({
    venueKey: venueId,
    includeRebuildState: false,
  })
  const { data: accessRulesSlideoutData, isLoading: isAccessRulesSlideoutDataLoading } = useAccessRulesSlideoutData()

  const earlyAccessAudienceId = useMemo(() => {
    const audienceHierarchy = flattenAudience(accessRulesSlideoutData?.audienceHierarchy).find(
      audienceHierarchy => audienceHierarchy.name === EARLY_ACCESS_AUDIENCE_HIERARCHY_NAME
    )
    return audienceHierarchy?.value
  }, [accessRulesSlideoutData?.audienceHierarchy])

  if (isErrorFetchingPerk) {
    notify({
      content: formatMessage(perksMessages.perkNotFound, { perkId: perkId || '' }),
      type: 'error',
    })
    nav.push(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })
  }

  const isLoading = isFetchingPerk || isClientTagGroupsLoading || isAccessRulesSlideoutDataLoading || isAccessRulesLoading

  return isLoading || !clientTagGroups || !accessRulesSlideoutData || !accessRulesByDay || !earlyAccessAudienceId ? (
    <Box p="lm" width="100%">
      <VStack spacing="lm">
        <Box width="968px">
          <Loader />
        </Box>
      </VStack>
    </Box>
  ) : (
    <CreateEditEarlyAccessPerkForm
      perk={perk}
      clientTagGroups={clientTagGroups}
      earlyAccessAudienceId={earlyAccessAudienceId}
      accessRulesSlideoutData={accessRulesSlideoutData}
      accessRulesByDay={accessRulesByDay}
    />
  )
}
