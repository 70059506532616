import onClickOutside from 'react-onclickoutside'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { TierAttributes } from 'mgr/pages/shared/utils/Constants'
import {
  Tier,
  TierTitle,
  TierEditing,
  TierContent,
  TierEditor,
} from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import BaseTier, { dropStyle, dropProps } from './BaseTier'

const requestCountChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

const requestSourceChoices = [
  { name: 'Sevenrooms', value: 'SEVENROOMS' },
  { name: 'Bookie', value: 'BOOKIE' },
]

const partySizeChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

const pastDaysMaxChoices = Array(180)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

export class ReservationRequestsTier extends BaseTier {
  constructor(props) {
    super(props)
  }

  handleClickOutside(e) {
    if (!this.props.tier.name_display && this.originalName === '') {
      this.props.removeTier()
    }
  }

  render() {
    const name = this.props.tier.name_display
    const { attributes } = this.props.tier
    const { isActive, id } = this.props
    const productProvisionPackage = this.props.venueProductProvisionPackage
    const displaySourceChoices = productProvisionPackage === 'MARKETING_ALPHA' || productProvisionPackage === 'MARKETING_BETA'
    return (
      <div id={id}>
        {this.props.editing ? (
          <TierEditing error={this.props.error}>
            {this.fetchTitle()}
            <TierEditor>
              Tag guests who have made at least
              <DropdownArrowsPicker
                {...dropProps}
                choices={requestCountChoices}
                value={attributes.MIN_RESERVATION_REQUESTS}
                style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
                optionsContainerStyle={{ maxHeight: 200 }}
                onChangeHandler={value => this.updateTierAttribute(TierAttributes.MIN_RESERVATION_REQUESTS, value)}
              />
              unfulfilled reservation requests through
              {displaySourceChoices ? (
                <DropdownArrowsPicker
                  {...dropProps}
                  choices={requestSourceChoices}
                  value={attributes.RESERVATION_REQUEST_SOURCE}
                  style={{ ...dropStyle, width: '160px', minWidth: '160px' }}
                  optionsContainerStyle={{ maxHeight: 200 }}
                  onChangeHandler={value => this.updateTierAttribute(TierAttributes.RESERVATION_REQUEST_SOURCE, value)}
                />
              ) : (
                ' Sevenrooms '
              )}
              with a party size of at least
              <DropdownArrowsPicker
                {...dropProps}
                choices={partySizeChoices}
                value={attributes.MIN_RESERVATION_REQUEST_PARTY_SIZE}
                style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
                optionsContainerStyle={{ maxHeight: 200 }}
                onChangeHandler={value => this.updateTierAttribute(TierAttributes.MIN_RESERVATION_REQUEST_PARTY_SIZE, value)}
              />
              in the past
              <DropdownArrowsPicker
                {...dropProps}
                choices={pastDaysMaxChoices}
                value={attributes.PAST_DAYS_MAX}
                style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
                optionsContainerStyle={{ maxHeight: 200 }}
                onChangeHandler={value => this.updateTierAttribute(TierAttributes.PAST_DAYS_MAX, value)}
              />
              days.
            </TierEditor>
            {this.fetchAdditionalOptions()}
          </TierEditing>
        ) : (
          <Tier onClick={this.toggleEditing} isActive={isActive}>
            <TierTitle>{name}</TierTitle>
            <TierContent>
              Tag guests who have made at least <strong>{attributes.MIN_RESERVATION_REQUESTS}</strong> unfulfilled reservation requests
              through <strong>{attributes.RESERVATION_REQUEST_SOURCE}</strong> with a party size of at least{' '}
              <strong>{attributes.MIN_RESERVATION_REQUEST_PARTY_SIZE}</strong> in the past <strong>{attributes.PAST_DAYS_MAX}</strong> days.
            </TierContent>
          </Tier>
        )}
      </div>
    )
  }
}

export default onClickOutside(ReservationRequestsTier)
