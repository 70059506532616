import { defineMessages } from '@sevenrooms/core/locales'

export const mgrAcctUpgradesMessages = defineMessages({
  availableUpgrades: {
    id: 'mgrAcctUpgradesMessages.availableUpgrades',
    defaultMessage: 'Available Upgrades',
  },
  textMarketing: {
    id: 'mgrAcctUpgradesMessages.textMarketing',
    defaultMessage: 'Text Marketing',
  },
  getDemo: {
    id: 'mgrAcctUpgradesMessages.getDemo',
    defaultMessage: 'Request Demo',
  },
  newFeatureFlag: {
    id: 'mgrAcctUpgradesMessages.newFeatureFlag',
    defaultMessage: 'New Feature Release',
  },
})
