import { useEffect, useState } from 'react'
import { useGetMarketingStatusQuery, useGetVenueProfileQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { ActionBanner, Box, HStack, SimpleSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text } from '@sevenrooms/core/ui-kit/typography'
import { EmailCampaignPerformanceContext } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { AutomatedEmailStatistics } from '../AutomatedEmailStatistics'
import { GuestSatisfactionStats } from '../components/GuestSatisfactionStats'
import heroImage from '../images/marketing-home-hero.svg'
import { marketingHomeMessages } from '../locales'
import { ActionItems } from './ActionItems'
import { BookieIntegrationModal } from './BookieIntegrationModal'
import { ImportPendingBanner } from './ImportPendingBanner'

export function MarketingHome() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { data: venueProfile } = useGetVenueProfileQuery({ venueId: venue.id })

  const [pollingInterval, setPollingInterval] = useState(5000)

  const { data: marketingStatus } = useGetMarketingStatusQuery({ venueId: venue.id }, { pollingInterval })

  const needsBookieMapping =
    marketingStatus && marketingStatus.reservationIntegrationType === 'BOOKIE' && marketingStatus.bookieIntegrationStatus !== 'MAPPED'
  const helpUrl = 'https://trysevenrooms.chilipiper.com/book/me/sunny-johnson/all'

  useEffect(() => {
    if (needsBookieMapping) {
      setPollingInterval(0)
    } else {
      setPollingInterval(5000)
    }
  }, [needsBookieMapping, setPollingInterval])

  return (
    <>
      {needsBookieMapping && (
        <BookieIntegrationModal
          bookieIntegrationStatus={marketingStatus?.bookieIntegrationStatus}
          bookieIntegrationUrl={marketingStatus?.bookieIntegrationUrl}
        />
      )}
      {marketingStatus?.dataImportPending && <ImportPendingBanner />}
      <Box backgroundImage={heroImage} height="364px" minWidth="900px">
        <HStack height="100%">
          <VStack height="100%" width="480px" justifyContent="center" pl="xl">
            <Box>
              <Text fontSize="3xl" color="lightFont" lineHeight="3xl">
                {formatMessage(marketingHomeMessages.heroVenueName, { venueName: venue.name })}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xxl" color="lightFont" lineHeight="xl">
                {formatMessage(marketingHomeMessages.heroWelcome)}
              </Text>
            </Box>
            <Box pt="s">
              <Text fontSize="m" color="lightFont" lineHeight="s">
                {formatMessage(marketingHomeMessages.heroIntro)}
              </Text>
            </Box>
          </VStack>
          {marketingStatus && <ActionItems marketingStatus={marketingStatus} venue={venue} />}
        </HStack>
      </Box>
      <Box minWidth="900px" mt="l" ml="m" mr="m">
        <HStack minWidth="900px" spacing="l">
          <EmailCampaignPerformanceContext>
            <AutomatedEmailStatistics />
          </EmailCampaignPerformanceContext>
          <GuestSatisfactionStats venueProfile={venueProfile} />
        </HStack>
        <VStack width="100%" mt="l" spacing="m">
          <Box ml="s" mb="l">
            <Header type="h2" lineHeight="l">
              {formatMessage(marketingHomeMessages.faqTitle)}
            </Header>
          </Box>
          <Box maxWidth="1004px">
            <ActionBanner
              icon={<Icon name="VMSWeb-headset-mic" size="lg" color="brandColor" />}
              title={formatMessage(marketingHomeMessages.faqActionTitle)}
              description={formatMessage(marketingHomeMessages.faqActionDescription)}
              to={helpUrl}
              isExternal
              pt="m"
              pb="m"
              pl="lm"
              pr="l"
            />
          </Box>
          <SimpleSection title={formatMessage(marketingHomeMessages.faq1question)} initiallyCollapsed maxWidth="1002px">
            <Box p="lm">
              <Text fontSize="m">
                {formatMessage(marketingHomeMessages.faq1answerPart1)}
                <br />
                <br />
                {formatMessage(marketingHomeMessages.faq1answerPart2)}{' '}
                <a href={helpUrl}>{formatMessage(marketingHomeMessages.faq1answerLinkText)}</a>{' '}
                {formatMessage(marketingHomeMessages.faq1answerPart3)}
              </Text>
            </Box>
          </SimpleSection>
          <SimpleSection title={formatMessage(marketingHomeMessages.faq2question)} initiallyCollapsed maxWidth="1002px">
            <Box p="lm">
              <Text fontSize="m">{formatMessage(marketingHomeMessages.faq2answer)}</Text>
            </Box>
          </SimpleSection>
          <SimpleSection title={formatMessage(marketingHomeMessages.faq3question)} initiallyCollapsed maxWidth="1002px">
            <Box p="lm">
              <Text fontSize="m">{formatMessage(marketingHomeMessages.faq3answer)}</Text>
            </Box>
          </SimpleSection>
          <SimpleSection title={formatMessage(marketingHomeMessages.faq4question)} initiallyCollapsed maxWidth="1002px">
            <Box p="lm">
              <Text fontSize="m">{formatMessage(marketingHomeMessages.faq4answer)}</Text>
            </Box>
          </SimpleSection>
          <SimpleSection title={formatMessage(marketingHomeMessages.faq5question)} initiallyCollapsed maxWidth="1002px">
            <Box p="lm">
              <Text fontSize="m">{formatMessage(marketingHomeMessages.faq5answer)}</Text>
            </Box>
          </SimpleSection>
        </VStack>
      </Box>
    </>
  )
}
