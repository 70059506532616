import { useMemo, useState } from 'react'
import { useGetExperiencesQuery } from '@sevenrooms/core/api'
import type { Experience } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useLocation, useNavigation, useScrollToTop } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, Flex, Window } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useUserContext, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { MarketingTemplatesGallery } from '@sevenrooms/mgr-marketing-templates-gallery'
import { ExperienceLinkModal } from './experiences/ExperienceLinkModal'
import { ExperiencesLocales } from './experiences/Experiences.locales'
import { ExperiencesList } from './experiences/ExperiencesList'

export function ExperiencesManagement() {
  const { pathname } = useLocation()
  useScrollToTop(pathname)
  const [viewLinkModal, setViewLinkModal] = useState<boolean>(false)
  const [activeExperience, setActiveExperience] = useState<null | Experience>(null)
  const { venueKey, venueId } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const user = useUserContext()
  const closeLinkModal = () => {
    if (venueSettings) {
      setViewLinkModal(false)
    }
    if (activeExperience != null) {
      setActiveExperience(null)
    }
  }
  const isSizzleSuperhero = venueSettings?.sizzle_superheroes_only && user?.is_superhero
  const sizzlePackageEnabled = venueSettings?.is_full_sizzle_package || venueSettings?.is_sizzle_standalone
  const isExperienceTemplatesEnabled = venueSettings?.is_experience_templates_enabled

  const isSizzleEnabled = isSizzleSuperhero || sizzlePackageEnabled
  const openExperienceLinkModal = (id: string) => {
    setActiveExperience(data?.results.find(experience => experience.id === id) || null)
  }
  const { data, isFetching } = useGetExperiencesQuery({ venueId })

  const experiencesList = useMemo(
    () => ({
      draft: data ? data.results.filter(experience => experience.isDraft) : [],
      active: data ? data.results.filter(experience => experience.isActive) : [],
      inactive: data ? data.results.filter(experience => experience.isInactive) : [],
    }),
    [data]
  )
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  return (
    <>
      <SettingsPageMeta title={formatMessage(ExperiencesLocales.experiencesTitle)} />
      <SettingsPageContent
        title={formatMessage(ExperiencesLocales.experiencesTitle)}
        headerWidth="calc(100% - 274px)"
        secondHeaderMaxWidth="968px"
        actions={
          <Flex>
            {isSizzleEnabled && (
              <Box mr="s">
                <Button variant="secondary" onClick={() => setViewLinkModal(true)} id="create-new-experience" data-test="offers-directory">
                  {formatMessage(ExperiencesLocales.offersDirectory)}
                </Button>
              </Box>
            )}
            <Button
              variant="primary"
              icon="VMSWeb-add"
              onClick={() =>
                nav.push(routes.manager2.marketing.offers.createExperience, {
                  params: { venueKey },
                })
              }
              id="create-new-experience"
              data-test="create-new-experience"
            >
              {formatMessage(ExperiencesLocales.createNew)}
            </Button>
          </Flex>
        }
      >
        <Box pl="lm">
          <Box maxWidth="968px">
            <MarketingTemplatesGallery isExperienceTemplatesEnabled={isExperienceTemplatesEnabled} />
            <ExperiencesList experiencesList={experiencesList} openExperienceLinkModal={openExperienceLinkModal} isFetching={isFetching} />
            <Window active={viewLinkModal || activeExperience != null}>
              <ExperienceLinkModal
                linkObject={activeExperience === null ? data?.offerDirLinks : activeExperience.staticLinks}
                closeExperienceLinkModal={closeLinkModal}
                activeExperience={activeExperience}
              />
            </Window>
          </Box>
        </Box>
      </SettingsPageContent>
    </>
  )
}
