import { useCallback } from 'react'
import { useCreatePromoCodeMutation, useEditPromoCodeMutation, isDuplicateError } from '@sevenrooms/core/api'
import type { PromoCode } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { notify } from '@sevenrooms/core/ui-kit/layout'
import type { Venue } from '@sevenrooms/mgr-core'
import { usePromoCodeContext } from '../hooks/usePromoCodeContext'
import { promoCodeMessages } from '../locales'

interface PromoCodeFormSaveButtonProps {
  venue: Venue
  isCreate: boolean
}

export function PromoCodeFormSaveButton({ venue, isCreate }: PromoCodeFormSaveButtonProps) {
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  const { isSaveDisabled, validateForm, getDataForSave, resetForm } = usePromoCodeContext()
  const [createPromoCode, createPromoCodeStatus] = useCreatePromoCodeMutation()
  const [editPromoCode, editPromoCodeStatus] = useEditPromoCodeMutation()
  const disabled = isSaveDisabled || createPromoCodeStatus.isLoading || editPromoCodeStatus.isLoading

  const onSaveClicked = useCallback(async () => {
    const isValid = validateForm()
    if (isValid) {
      const promoCode: PromoCode = getDataForSave()
      let savePromise
      if (isCreate) {
        savePromise = createPromoCode({ venueId: venue.id, promoCode })
      } else {
        savePromise = editPromoCode({ venueId: venue.id, promoCode })
      }
      try {
        await savePromise
        resetForm()
        const successMessageKey = isCreate ? promoCodeMessages.promoCodeCreateSuccessMessage : promoCodeMessages.promoCodeEditSuccessMessage
        notify({ content: formatMessage(successMessageKey), type: 'success' })
        nav.push(routes.manager2.marketing.promoCodesManagement, {
          params: { venueKey: venue.urlKey },
        })
      } catch (msg) {
        const errorMessage = isDuplicateError(`${msg}`)
          ? promoCodeMessages.promoCodeSaveDuplicateErrorMessage
          : promoCodeMessages.promoCodeSaveErrorMessage
        notify({ content: formatMessage(errorMessage), type: 'error' })
      }
    }
  }, [createPromoCode, editPromoCode, formatMessage, getDataForSave, isCreate, nav, resetForm, validateForm, venue.id, venue.urlKey])

  return (
    <Button variant="primary" disabled={disabled} onClick={disabled ? undefined : onSaveClicked} data-test="save-button">
      {formatMessage(commonMessages.saveChanges)}
    </Button>
  )
}
