import { useGetGuestSatisfactionQuery } from '@sevenrooms/core/api'
import type { VenueProfile } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Flex, HStack, VStack, CardSection, DividerLine, Loader } from '@sevenrooms/core/ui-kit/layout'
import { Text, Header } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import {
  computeReviewsStats,
  type Site,
} from '../../../../application/site/static/app/manager/pages/src/single-venue/marketing/selectors/Reviews'
import { marketingHomeMessages } from '../locales'

interface GuestSatisfactionStatsProps {
  venueProfile?: VenueProfile
}

function GuestSatisfactionNotConfigured() {
  const { formatMessage } = useLocales()
  const { venueKey } = useVenueContext()
  const nav = useNavigation()
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height="198px"
      backgroundColor="insetBackground"
      flexDirection="column"
      mt="m"
      borderRadius="s"
    >
      <VStack alignItems="center" pt="m" pb="m" spacing="m">
        <Text fontSize="xl" fontWeight="bold">
          {formatMessage(marketingHomeMessages.noReviews)}
        </Text>
        <Button
          data-test="add-review-sites-button"
          href={nav.href(routes.manager2.marketing.profileInformation, { params: { venueKey } })}
          size="s"
        >
          {formatMessage(marketingHomeMessages.addReviewSites)}
        </Button>
      </VStack>
    </Flex>
  )
}

export function GuestSatisfactionStats({ venueProfile }: GuestSatisfactionStatsProps) {
  const { venue, venueKey } = useVenueContext()
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  const { data, isLoading: isGuestSatisfactionLoading } = useGetGuestSatisfactionQuery({ venueId: venue.id, limit: 1 })
  const displayStats = venueProfile?.reviewSites && Object.values(venueProfile.reviewSites).some(x => !!x) && data?.stats
  const reviewsSites = data?.stats ? Object.keys(data.stats) : []
  const reviewsStats = displayStats
    ? computeReviewsStats({
        reviewsByChannel: data.stats,
        sitesforStarAggregates: reviewsSites as Site[],
        sitesForTotals: reviewsSites as Site[],
      })
    : null
  const mostRecentReview = data?.results.length ? data.results[0] : null
  const isLoading = isGuestSatisfactionLoading || (displayStats && !reviewsStats)

  return (
    <CardSection width="486px" maxHeight="332px" p="m" backgroundColor="primaryBackground" borderColor="dividerLines">
      <HStack justifyContent="space-between" pt="s">
        <Header type="h2" lineHeight="l">
          {formatMessage(marketingHomeMessages.guestSatisfactionTitle)}
        </Header>
        <Button
          data-test="view-guest-satisfaction-button"
          variant="tertiary"
          noPadding
          type="button"
          href={nav.href(routes.manager2.marketing.reviews, { params: { venueKey } })}
        >
          {formatMessage(marketingHomeMessages.guestSatisfactionAction)}
        </Button>
      </HStack>
      {isLoading && <Loader />}
      {!isLoading && !displayStats && <GuestSatisfactionNotConfigured />}
      {displayStats && reviewsStats && (
        <VStack pt="s">
          <Text>{formatMessage(marketingHomeMessages.guestSatisfactionDescription)}</Text>
          <HStack justifyContent="space-between" pt="l">
            <Text color="primaryIcons">{formatMessage(marketingHomeMessages.overallAverage)}</Text>
            <Text fontWeight="bold">
              {reviewsStats.overall.average}
              <Icon name="VMSWeb-star" color="warning" size="lg" />
            </Text>
          </HStack>
          <DividerLine margin="none" mt="s" mb="s" />
          <HStack justifyContent="space-between" pt="s">
            <Text color="primaryIcons">{formatMessage(marketingHomeMessages.guestSatisfactionPositive)}</Text>
            <Text fontWeight="bold" color="primaryIcons">
              {reviewsStats.breakdown.percentages.positive}%
            </Text>
          </HStack>
          <DividerLine margin="none" mt="s" mb="s" />
          <HStack justifyContent="space-between" pt="s">
            <Text color="primaryIcons">{formatMessage(marketingHomeMessages.guestSatisfactionNegative)}</Text>
            <Text fontWeight="bold" color="primaryIcons">
              {reviewsStats.breakdown.percentages.negative}%
            </Text>
          </HStack>
          <DividerLine margin="none" mt="s" mb="s" />
          {mostRecentReview && (
            <VStack pt="s">
              <Text color="primaryIcons">{formatMessage(marketingHomeMessages.guestSatisfactionRecentReview)}</Text>
              <HStack justifyContent="space-between" pb="s">
                <Text color="secondaryFont">{mostRecentReview.reviewSiteName}</Text>
                <Text fontWeight="bold">
                  {mostRecentReview.rating}
                  <Icon name="VMSWeb-star" color="warning" size="lg" />
                </Text>
              </HStack>
              <Text fontStyle="italic" textOverflow="twoLinesEllipsis">
                {mostRecentReview.notes}
              </Text>
            </VStack>
          )}
        </VStack>
      )}
    </CardSection>
  )
}
