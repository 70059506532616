export function GetTemplateImages() {
  function importAll(r: __WebpackModuleApi.RequireContext) {
    const images = {}
    r.keys().map(item => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      images[item.replace('./', '')] = r(item)
      return images
    })
    return images
  }
  const allImages = importAll(
    require.context('@sevenrooms/mgr-marketing-templates-gallery/assets/experiences/images', false, /\.(png|jpe?g|svg)$/)
  )
  return allImages
}
