import { type InternationalizationText, useLocales } from '@sevenrooms/core/locales'
import { type Destination, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import type { SideNavigationCategoryItem, SideNavigationPageItem } from '@sevenrooms/core/ui-kit/layout'
import { marketingCommonMessages } from '@sevenrooms/marketing'
import { type AvailableUpgrades, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { SMSMarketingUpgrade } from '@sevenrooms/mgr-marketing/views/Upsells/SMSMarketingUpgrade'
import { mgrAcctUpgradesMessages } from '../locales'
import type { ReactElement } from 'react'

const CONFIG_BY_AVAILABLE_UPGRADE: Record<
  AvailableUpgrades,
  {
    title: InternationalizationText
    route: Destination<string, unknown, 'page'>
    component: () => ReactElement
  }
> = {
  sms_marketing: {
    title: marketingCommonMessages.textMarketing,
    route: routes.manager2.marketing.upgrades.textMarketing,
    component: SMSMarketingUpgrade,
  },
} as const

export const useAvailableUpgradeNavigationItems = (filter: Set<AvailableUpgrades>): Array<SideNavigationCategoryItem> => {
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const { formatMessage } = useLocales()

  if (!venue || !venue.urlKey || !venueSettings) {
    return []
  }

  const venueKeyParams = { params: { venueKey: venue.urlKey } }

  const formattedAvailableUpgrades: SideNavigationPageItem[] = []
  for (const availableUpgrade of venueSettings.available_upgrades) {
    const config = CONFIG_BY_AVAILABLE_UPGRADE[availableUpgrade]
    if (filter.has(availableUpgrade) && config) {
      formattedAvailableUpgrades.push({
        id: availableUpgrade,
        title: formatMessage(config.title),
        reload: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        to: nav.href(config.route, venueKeyParams),
        items: [],
        routeConfigs: [
          {
            path: config.route.path,
            component: config.component,
          },
        ],
      })
    }
  }

  return formattedAvailableUpgrades.length
    ? [
        {
          id: 'available_upgrades',
          title: formatMessage(mgrAcctUpgradesMessages.availableUpgrades),
          items: formattedAvailableUpgrades,
        },
      ]
    : []
}
