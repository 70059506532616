import { useEffect, useState } from 'react'
import { type SMSCampaignTemplate, useGetOneTimeSMSCampaignTemplatesQuery } from '@sevenrooms/core/api'
import { SMSCampaignTemplateCategoryEnum } from '@sevenrooms/core/domain'
import { useForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { FormMultiSelect, BasicButton, IconButton } from '@sevenrooms/core/ui-kit/form'
import { Box, Flex, Grid, HStack, Loader, VStack, BorderedBox, Breadcrumbs } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text, Link, StatusLabel } from '@sevenrooms/core/ui-kit/typography'
import { campaignCenterMessages, useOneTimeSMSCampaignTemplateCategories } from '@sevenrooms/marketing'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { oneTimeSMSCenterMessages } from '../locales'
import { SMSTemplatePreview } from './SMSTemplatePreview'

const EMAIL_CAMPAIGN_CATEGORY_FILTER_WIDTH = '216px'
const CAMPAIGN_TEMPLATE_CARD_HEIGHT = '354px'
const CAMPAIGN_TEMPLATE_CARD_WIDTH = '266px'

export function SMSCampaignTemplates() {
  const [isCreateCardHover, setCreateCardHover] = useState(false)
  const { venue, venueId } = useVenueContext()
  const nav = useNavigation()
  const { data, isFetching } = useGetOneTimeSMSCampaignTemplatesQuery({ venueId, hasMedia: venue.isMMSEnabled })
  const categories = useOneTimeSMSCampaignTemplateCategories()
  const { formatMessage } = useLocales()
  const [templates, setTemplates] = useState<undefined | SMSCampaignTemplate[]>()

  const { field, watch } = useForm<typeof filtersSchema>(filtersSchema)
  const [selectedCategories] = watch(['selectedCategories'])

  useEffect(() => {
    setTemplates(
      data?.templates
        .filter((template: SMSCampaignTemplate) =>
          selectedCategories && selectedCategories.length > 0
            ? selectedCategories.find(selectedCategory => template.templateCategories?.includes(selectedCategory))
            : true
        )
        .map((template: SMSCampaignTemplate) => ({ ...template, messageBody: template.messageBody?.replace(/[\n\r]/g, '<br />') }))
    )
  }, [data, selectedCategories])

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(oneTimeSMSCenterMessages.smsTemplatesTitle)} />
      <SettingsPageContent
        title={formatMessage(oneTimeSMSCenterMessages.smsTemplatesTitle)}
        breadcrumbs={
          <Breadcrumbs>
            <Link
              to={nav.href(routes.manager2.marketing.oneTimeSMSCenter, {
                params: { venueKey: venue.urlKey },
                queryMode: 'clear',
              })}
            >
              {formatMessage(oneTimeSMSCenterMessages.smsCampaignTemplatesLinkMessage)}
            </Link>
            <Text>{formatMessage(oneTimeSMSCenterMessages.smsCampaignTemplatesBreadcrumbText)}</Text>
          </Breadcrumbs>
        }
      >
        <Box pb="m" pl="m" pr="m" width="100%">
          <Flex mb="m">
            <Box minWidth={EMAIL_CAMPAIGN_CATEGORY_FILTER_WIDTH}>
              <FormMultiSelect
                placeholder={formatMessage(campaignCenterMessages.categoryFilterPlaceholder)}
                field={field.prop('selectedCategories')}
                displayAsTextWhenCollapsed
                options={categories}
                showMoreLabel=""
                useCheckboxItems
                usePills={false}
                scrollLock
              />
            </Box>
          </Flex>
        </Box>
        <Box pb="m" pl="m" pr="m" width="100%">
          {isFetching ? (
            <Loader />
          ) : (
            <Grid
              gridTemplateColumns={`repeat(auto-fill, minmax(${CAMPAIGN_TEMPLATE_CARD_WIDTH}, ${CAMPAIGN_TEMPLATE_CARD_WIDTH}))`}
              columnGap="m"
              alignItems="start"
              rowGap="xl"
            >
              <BasicButton
                onClick={() => {
                  nav.push(routes.manager2.marketing.oneTimeSMSCenter.createOneTimeSMS, {
                    params: { venueKey: venue.urlKey },
                    queryMode: 'clear',
                  })
                }}
              >
                <BorderedBox backgroundColor="primaryBackground">
                  <Flex
                    maxHeight={CAMPAIGN_TEMPLATE_CARD_HEIGHT}
                    maxWidth={CAMPAIGN_TEMPLATE_CARD_WIDTH}
                    height={CAMPAIGN_TEMPLATE_CARD_HEIGHT}
                    width={CAMPAIGN_TEMPLATE_CARD_WIDTH}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    flexGrow="1"
                    flex="none"
                    pl="sm"
                    pr="sm"
                    pt="m"
                    pb="m"
                    onMouseOver={() => setCreateCardHover(true)}
                    onFocus={() => setCreateCardHover(true)}
                    onMouseOut={() => setCreateCardHover(false)}
                    onBlur={() => setCreateCardHover(false)}
                  >
                    <VStack alignItems="center" justifyContent="center" spacing="m">
                      <IconButton
                        borderType="round"
                        icon="VMSWeb-add"
                        iconBackgroundColor={isCreateCardHover ? 'primaryButton' : undefined}
                        iconColor={isCreateCardHover ? 'lightFont' : undefined}
                        hoverIconBackgroundColor={isCreateCardHover ? 'primaryHover' : undefined}
                        iconBorderColor={isCreateCardHover ? 'lightFont' : undefined}
                      />
                      <Header>{formatMessage(oneTimeSMSCenterMessages.smsTemplateEmptyCardTitle)}</Header>
                      <Text>{formatMessage(oneTimeSMSCenterMessages.smsTemplateEmptyCardText)}</Text>
                    </VStack>
                  </Flex>
                </BorderedBox>
              </BasicButton>
              {templates?.map((template: SMSCampaignTemplate) => (
                <VStack key={template.id}>
                  <SMSTemplatePreview
                    cardHeight={CAMPAIGN_TEMPLATE_CARD_HEIGHT}
                    cardWidth={CAMPAIGN_TEMPLATE_CARD_WIDTH}
                    cardBackgroudColor="primaryBackground"
                    template={template}
                    scrollable={false}
                  />
                  <Box mt="s">
                    <Text textStyle="h3">{template.campaignName}</Text>
                  </Box>
                  <HStack mt="s" spacing="xs" maxWidth={CAMPAIGN_TEMPLATE_CARD_WIDTH} flexWrap="wrap" rowGap="xs">
                    {template.templateCategories?.map((category: string) => (
                      <StatusLabel variant="inactive" key={category}>
                        {categories.find(({ id }) => id === category)?.label}
                      </StatusLabel>
                    ))}
                  </HStack>
                </VStack>
              ))}
            </Grid>
          )}
        </Box>
      </SettingsPageContent>
    </>
  )
}

const filtersSchema = z.object({
  selectedCategories: z.array(z.nativeEnum(SMSCampaignTemplateCategoryEnum)),
})
