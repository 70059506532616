import React from 'react'
import ReactDOM from 'react-dom'
import { LegacyMarketingSideNavigation } from '@sevenrooms/mgr-marketing'
import { LegacySideNavigation } from '@sevenrooms/mgr-settings/Settings'
import { svrExport } from '@sevenrooms/core/utils'

export const initLeftNavBarSettings = () => {
  ReactDOM.render(<LegacySideNavigation />, document.getElementById('sidebar'))
}

svrExport('SettingPage', 'initLeftNavBarSettings', initLeftNavBarSettings)

export const initLeftNavBarMarketing = () => {
  ReactDOM.render(<LegacyMarketingSideNavigation />, document.getElementById('sidebar'))
}

svrExport('MarketingPage', 'initLeftNavBarMarketing', initLeftNavBarMarketing)
